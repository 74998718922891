import React from "react";
import Card from "react-bootstrap/Card";
import EventDivisionPlayers from "./EventDivisionPlayers";
import EventDivisionPlayersNotGolf from "./EventDivisionPlayersNotGolf";

function EventDivisionsCard({ item, index, ...props }) {
  const bg_color = item.divisions.bg_color ? item.divisions.bg_color : "ffffff";
  const text_color = item.divisions.text_color
    ? item.divisions.text_color
    : "000000";

  const headerStyle = {
    background: `#` + bg_color,
    borderBottom: "5px solid black",
  };

  const titleStyle = {
    color: `#` + text_color,
    paddingBottom: "0px",
  };

  const divisionName = item.divisions.name ? item.divisions.name : "Division";

  return (
    <Card className="mb-3" key={index}>
      <Card.Body className="px-0 pt-0">
        {props.scoringOrderGolf ? (
          <>
            <EventDivisionPlayers
              isEventComplete={props.isEventComplete}
              event_divisions_id={item.event_divisions_id}
              division_id={item.division_id}
              eventId={props.eventId}
            />
          </>
        ) : (
          <>
            <EventDivisionPlayersNotGolf
              divisionName={divisionName}
              titleStyle={titleStyle}
              headerStyle={headerStyle}
              isEventComplete={props.isEventComplete}
              event_divisions_id={item.event_divisions_id}
              division_id={item.division_id}
              eventId={props.eventId}
              throwScoringModeId={props.throwScoringModeId}
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default EventDivisionsCard;
