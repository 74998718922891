import { Tabs, Tab } from "react-bootstrap";
import ScorecardSummaryTable from "./ScorecardSummaryTable";
import ScorecardSummaryTable5x4 from "./ScorecardSummaryTable5x4";

function ScorecardSummaryTabs({
  holes,
  players,
  onJumpToHole,
  throwScoringModeId,
}) {
  return (
    <Tabs defaultActiveKey="summary" id="scorecard_summary">
      <Tab eventKey="summary" title="All">
        <ScorecardSummaryTable
          holes={holes}
          players={players}
          onJumpToHole={onJumpToHole}
          throwScoringModeId={throwScoringModeId}
        />
      </Tab>

      {players.map((player, index) => {
        return (
          <Tab
            key={index}
            eventKey={index}
            title={player.event_entries.users_public.name}
          >
            <ScorecardSummaryTable5x4
              eventEntryId={player.event_entries_id}
              throwScoringModeId={throwScoringModeId}
            />
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default ScorecardSummaryTabs;
