import React, { useEffect } from "react";
import { useAuth } from "./../util/auth";
import { useState } from "react";
import SectionHeader from "./../components/SectionHeader";
import AdminPageUpdateHost from "../components/AdminPageUpdateHost";

function EventLdPage() {
  const auth = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  //   get eventid from the url
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event");

  //useeffect to reset is admin on auth.user change
  useEffect(() => {
    if (auth.user && auth.user.user_access_level_id === 3) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [auth.user]);

  return (
    <>
      <SectionHeader
        title={"Update Host"}
        // subtitle={props.subtitle}
        size={1}
        spaced={true}
        className="text-center mt-3 mb-1"
      />
      {isAdmin ? (
        <AdminPageUpdateHost eventId={event_id} />
      ) : (
        <p>Please log in as an authorized user to continue</p>
      )}
    </>
  );
}

export default EventLdPage;
