import { Button, Col, Row } from "react-bootstrap";
// import ScorecardSummaryTablePreview from './ScorecardSummaryTablePreview';
import { useRouter } from "../util/router";
import ScorecardSummaryTable5x4 from "./ScorecardSummaryTable5x4";

function ScorecardBeginCardInProgress({ item, gameId, throwScoringModeId }) {
  const router = useRouter();

  return (
    <div className="text-center">
      <Row>
        <Col>
          {/* <ScorecardSummaryTablePreview scorecard={item.group_scorecard_players[0].group_scorecard_id} eventEntryId={item.id} /> */}
          <ScorecardSummaryTable5x4
            scorecard={item.group_scorecard_players[0].group_scorecard_id}
            eventEntryId={item.id}
            throwScoringModeId={throwScoringModeId}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            style={{ background: "#4D81F4", fontWeight: "bold", border: "0" }}
            // if gameid1 is 2, go to throwscorecard. else go to scorecard
            // onClick={gameId === 2 ? () => router.push(`/throws_scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`) : () => router.push(`/scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`)}
            onClick={() =>
              router.push(
                `/throws_scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`
              )
            }
            className="mt-2"
          >
            Resume Scorecard
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ScorecardBeginCardInProgress;
