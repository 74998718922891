import React from "react";
import AdminPagePlayersRowName from "./AdminPagePlayersRowName";
import AdminPagePlayersRowNamePublic from "./AdminPagePlayersRowNamePublic";
import AdminPagePlayersRowHost from "./AdminPagePlayersRowHost";
import AdminPagePlayersRowAdmin from "./AdminPagePlayersRowAdmin";
import AdminPagePlayersRowSchool from "./AdminPagePlayersRowSchool";

function AdminPagePlayersRow({ item }) {
  // console.log(item);
  return (
    <tr>
      <AdminPagePlayersRowName item={item} />
      <AdminPagePlayersRowNamePublic item={item} />
      <td>{item.email}</td>
      <AdminPagePlayersRowHost id={item.id} is_td={item.is_td} />
      <AdminPagePlayersRowAdmin
        id={item.id}
        user_access_level_id={item.user_access_level_id}
      />
      <td>{item.phonenumber}</td>
      <AdminPagePlayersRowSchool id={item.id} />
      {/* school_id={item.school_id}  */}
    </tr>
  );
}

export default AdminPagePlayersRow;
