import React from "react";
import FormAlert from "./FormAlert";
import { usePlayersByEvent } from "./../util/db";
import { Container } from "react-bootstrap";
import EventManagePlayersRow from "./EventManagePlayersRow";
import Table from "react-bootstrap/Table";

function EventManagePlayers(props) {
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = usePlayersByEvent(props.eventId);

  // const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {itemsStatus !== "loading" && items && items.length > 0 && (
        <>
          <Table responsive size="sm">
            <thead>
              <tr className="text-center" style={{ color: "#2D083F" }}>
                <th>Player</th>
                <th>Score</th>
                <th>Update</th>
                {props.throwScoringModeId > 1 && <th>Score+</th>}
                {props.throwScoringModeId > 1 && <th>Update+</th>}

                <th>Clear</th>
                <th>DNF</th>
                <th>Division</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {items.map((player, index) => (
                <EventManagePlayersRow
                  key={index}
                  eventId={props.eventId}
                  player={player}
                  throwScoringModeId={props.throwScoringModeId}
                />
              ))}
            </tbody>
          </Table>
        </>
      )}

      {itemsStatus !== "loading" && items && items.length === 0 && (
        <Container>No players yet</Container>
      )}
    </>
  );
}

export default EventManagePlayers;
