import React from "react";
import Button from "react-bootstrap/Button";

function ScorecardBackToScorecardButton({ router }) {
  return (
    <div className="d-flex justify-content-center mt-4">
      <Button
        onClick={() =>
          router.push(`/throws_scorecard?scorecard=${router.query.scorecard}`)
        }
      >
        Back to Scorecard
      </Button>
    </div>
  );
}

export default ScorecardBackToScorecardButton;
