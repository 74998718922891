import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useGroupScorecardPlayer } from "../util/db";
import ScorecardSummaryTable5x4ColorsTotals from "./ScorecardSummaryTable5x4ColorsTotals";

function ScorecardSummaryTable5x4({ eventEntryId, throwScoringModeId }) {
  const { data: holes } = useGroupScorecardPlayer(eventEntryId);
  const [holeScores, setHoleScores] = useState([]);
  const [numReds, setNumReds] = useState(0);
  const [numWhites, setNumWhites] = useState(0);
  const [numBlues, setNumBlues] = useState(0);
  const [numYellows, setNumYellows] = useState(0);
  const [numStationA, setNumStationA] = useState(0);
  const [numStationB, setNumStationB] = useState(0);
  const [numStationC, setNumStationC] = useState(0);
  const [numStationD, setNumStationD] = useState(0);
  const [numStationE, setNumStationE] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPlus, setTotalPlus] = useState(0);

  useEffect(() => {
    if (holes) {
      const scores = [];
      let scores_total = 0;
      let scores_total_plus = 0;
      holes.forEach((hole) => {
        scores.push({
          hole_order: hole.layout_holes.hole_order,
          score: hole.score,
          hole_score_adv: hole.hole_score_adv,
        });
        scores_total += hole.score;
        scores_total_plus += hole.hole_score_adv;
      });

      scores.sort((a, b) => a.hole_order - b.hole_order);

      setHoleScores(scores);
      setTotal(scores_total);
      setTotalPlus(scores_total_plus);

      //reds = 1,5,9,13,17
      //whites = 2,6,10,14,18
      //blues = 3,7,11,15,19
      //yellows = 4,8,12,16,20

      let reds = 0;
      let whites = 0;
      let blues = 0;
      let yellows = 0;

      scores.forEach((hole, index) => {
        if (
          hole.hole_order === 1 ||
          hole.hole_order === 5 ||
          hole.hole_order === 9 ||
          hole.hole_order === 13 ||
          hole.hole_order === 17
        ) {
          reds += hole.score;
        } else if (
          hole.hole_order === 2 ||
          hole.hole_order === 6 ||
          hole.hole_order === 10 ||
          hole.hole_order === 14 ||
          hole.hole_order === 18
        ) {
          whites += hole.score;
        } else if (
          hole.hole_order === 3 ||
          hole.hole_order === 7 ||
          hole.hole_order === 11 ||
          hole.hole_order === 15 ||
          hole.hole_order === 19
        ) {
          blues += hole.score;
        } else if (
          hole.hole_order === 4 ||
          hole.hole_order === 8 ||
          hole.hole_order === 12 ||
          hole.hole_order === 16 ||
          hole.hole_order === 20
        ) {
          yellows += hole.score;
        }
      });

      setNumReds(reds);
      setNumWhites(whites);
      setNumBlues(blues);
      setNumYellows(yellows);

      let stationA = 0;
      let stationB = 0;
      let stationC = 0;
      let stationD = 0;
      let stationE = 0;

      scores.forEach((hole, index) => {
        if (hole.hole_order <= 4) {
          stationA += hole.score;
        } else if (hole.hole_order <= 8) {
          stationB += hole.score;
        } else if (hole.hole_order <= 12) {
          stationC += hole.score;
        } else if (hole.hole_order <= 16) {
          stationD += hole.score;
        } else if (hole.hole_order <= 20) {
          stationE += hole.score;
        }
      });

      setNumStationA(stationA);
      setNumStationB(stationB);
      setNumStationC(stationC);
      setNumStationD(stationD);
      setNumStationE(stationE);
    }
  }, [holes]);

  return (
    <Table
      responsive
      bordered
      style={{ tableLayout: "auto", border: "2px solid #2D083F" }}
    >
      <tbody>
        {[...Array(5)].map((_, rowIndex) => (
          <tr key={rowIndex}>
            {holeScores
              .slice(rowIndex * 4, rowIndex * 4 + 4)
              .map((hole, index) => (
                <td
                  key={index}
                  className="text-center align-middle position-relative"
                  style={{
                    width: "50px",
                    height: "50px",
                    tableLayout: "auto",
                    border: "2px solid #2D083F",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="position-absolute"
                    style={{
                      top: "0",
                      right: "0",
                      width: "20px",
                      height: "20px",
                      backgroundColor:
                        index === 0
                          ? "red"
                          : index === 1
                          ? "white"
                          : index === 2
                          ? "blue"
                          : "yellow",
                      color: index === 1 || index === 3 ? "black" : "white",
                      border: "1px solid black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "12px",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    {hole.hole_order}
                  </div>

                  {/* if throwscoringmode>1, box in lower right corner with hole.hole_score_adv */}
                  {throwScoringModeId > 1 && (
                    <div
                      className="position-absolute"
                      style={{
                        bottom: "0",
                        right: "0",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      {hole.score}
                    </div>
                  )}

                  {/* 🚫 */}
                  {/* {hole.score === 5 ? "⭐️" : hole.score} */}
                  {/* {hole.score} */}
                  {throwScoringModeId > 1 ? hole.hole_score_adv : hole.score}
                </td>
              ))}
            {/* <td
              className="text-center align-middle position-relative"
              style={{
                width: "50px",
                height: "50px",
                tableLayout: "auto",
                border: "2px solid #2D083F",
                cursor: "pointer",
              }}
            >
              {rowIndex + 1 === 1
                ? numStationA
                : rowIndex + 1 === 2
                ? numStationB
                : rowIndex + 1 === 3
                ? numStationC
                : rowIndex + 1 === 4
                ? numStationD
                : numStationE}
            </td> */}
          </tr>
        ))}
        {/* <ScorecardSummaryTable5x4ColorsTotals
          numYellows={numYellows}
          numBlues={numBlues}
          numWhites={numWhites}
          numReds={numReds}
          total={total}
          eventEntryId={eventEntryId}
        /> */}
        <tr>
          <td
            colSpan="4"
            className="text-center align-middle"
            style={{
              width: "30px",
              tableLayout: "auto",
              border: "2px solid #2D083F",
              position: "relative",
            }}
          >
            {/* {total} */}
            {throwScoringModeId > 1 ? totalPlus : total}
            {throwScoringModeId > 1 && (
              <span
                style={{
                  position: "absolute",
                  top: "2px",
                  right: "2px",
                  fontSize: "10px",
                  background: "#FFFFFF",
                  border: "1px solid #2D083F",
                  padding: "2px",
                }}
              >
                {total}
              </span>
            )}
          </td>
        </tr>
      </tbody>
      {/* <tfoot>
        <tr>
          <td
            colSpan="4"
            className="text-center align-middle"
            style={{
              width: "30px",
              tableLayout: "auto",
              border: "2px solid #2D083F",
            }}
          >
            {total}
          </td>
        </tr>
      </tfoot> */}
    </Table>
  );
}

export default ScorecardSummaryTable5x4;
