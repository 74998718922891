import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSchools, useUsersSchool, updateUsersSchool } from "../util/db";

function AdminPagePlayersRowSchool({ id }) {
  const [isEditing, setIsEditing] = useState(false);
  const [schoolId, setSchoolId] = useState();
  const [schoolName, setSchoolName] = useState();

  const {
    data: schools,
    // status: schoolsStatus,
    error: schoolsError,
  } = useSchools();

  const {
    data: school,
    // status: schoolStatus,
    error: schoolError,
  } = useUsersSchool(id);

  useEffect(() => {
    if (school && school[0] && school[0].schools && school[0].schools.name) {
      setSchoolName(school[0].schools.name);
      setSchoolId(school[0].school_id);
    }
  }, [school]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      if (schoolId) {
        await updateUsersSchool(id, schoolId);
        const selectedSchool = schools.find((s) => s.id === schoolId);
        if (selectedSchool) {
          setSchoolName(selectedSchool.name);
        }
        setIsEditing(false);
      } else {
        alert("Please select a school");
      }
    } catch (error) {
      console.error("Error updating user's school:", error);
      alert("An error occurred while updating the school. Please try again.");
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const onSchoolChange = (e) => {
    setSchoolId(e.target.value);
  };

  if (schoolsError) {
    console.error("Error fetching schools:", schoolsError);
    return <td>Error loading schools</td>;
  }

  if (schoolError) {
    console.error("Error fetching user's school:", schoolError);
    return <td>Error loading user's school</td>;
  }

  return (
    <td>
      {isEditing ? (
        <>
          <select value={schoolId || ""} onChange={onSchoolChange}>
            {schools &&
              schools.map((school) => (
                <option key={school.id} value={school.id}>
                  {school.name}
                </option>
              ))}
          </select>
          <Button onClick={handleSaveClick}>Save</Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </>
      ) : (
        <>
          {schoolName}
          <span
            onClick={handleEditClick}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          >
            ✏️
          </span>
        </>
      )}
    </td>
  );
}

export default AdminPagePlayersRowSchool;
