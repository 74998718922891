import { Link } from "react-router-dom/cjs/react-router-dom.min";

function EventsTableRow({ event }) {
  const eventDate = new Date(event.date);
  const utcDate = new Date(
    eventDate.getUTCFullYear(),
    eventDate.getUTCMonth(),
    eventDate.getUTCDate()
  );
  const formattedDate = utcDate.toLocaleDateString();

  return (
    <tr>
      <td className="text-center">{formattedDate}</td>
      <td className="text-start">
        <Link to={`/event?event=${event.id}`}>{event.name}</Link>
      </td>
      {/* <td className="text-center">{event.games.name}</td> */}
      <td>
        {/* if courses.course_name is not null, print the name*/}
        {/* {event.courses ? event.courses.course_name : ""} */}
        {/* link to venue?id= */}
        {/* <Link to={`/venue?id=${event.course_id}`}> */}
        {event.courses.course_name}
        {/* </Link> */}
      </td>
    </tr>
  );
}

export default EventsTableRow;
