import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import FormAlert from "./../components/FormAlert";
import { requireAuth } from "./../util/auth";
import { redirectToCheckout } from "./../util/stripe";

function PaymentPage(props) {
  const [formAlert, setFormAlert] = useState();
  // const [totalCharge] = useState((props.location.state.signupObject.division_cost + props.location.state.signupObject.side_games_cost) * 100)
  // const [entryDescription] = useState('Registration summary');
  // const [signupObject] = useState(props.location.state.signupObject)

  let totalCharge =
    (props.location.state.signupObject.division_cost +
      props.location.state.signupObject.side_games_cost) *
    100;
  let entryDescription = "Registration summary";
  let signupObject = props.location.state.signupObject;

  console.log("totalCharge", totalCharge);
  console.log("entryDescription", entryDescription);
  console.log("signupObject", signupObject);

  useEffect(() => {
    redirectToCheckout(totalCharge, entryDescription, signupObject).catch(
      (error) => {
        console.log("totalCharge", totalCharge);
        console.log("entryDescription", entryDescription);
        console.log("signupObject", signupObject);

        console.log("error", error);
        setFormAlert({
          type: "error__",
          message: error.message,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Meta title="Signup" />
      <PageLoader>
        {formAlert && (
          <FormAlert
            type={formAlert.type}
            message={formAlert.message}
            style={{ maxWidth: "500px" }}
          />
        )}
      </PageLoader>
    </>
  );
}

export default requireAuth(PaymentPage);
