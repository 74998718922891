import React from "react";
import scorenav_left from "../media/scorenav_left.png";
import scorenav_right from "../media/scorenav_right.png";
import Table from "react-bootstrap/Table";

function ScorecardNavbarTop({
  currentHole,
  onLeftNav,
  onRightNav,
  showSummary,
  toggleSummary,
}) {
  let backgroundColor = "";
  let textColor = "";

  if (currentHole % 4 === 0) {
    backgroundColor = "#FFC000";
    textColor = "#000000";
  } else if (currentHole % 4 === 1) {
    backgroundColor = "#C00000";
    textColor = "#FFFFFF";
  } else if (currentHole % 4 === 2) {
    backgroundColor = "#FFFFFF";
    textColor = "#000000";
  } else if (currentHole % 4 === 3) {
    backgroundColor = "#4477CE";
    textColor = "#FFFFFF";
  }
  if (showSummary === true) {
    backgroundColor = "#FFFFFF";
    textColor = "#000000";
  }

  return (
    <Table className="mb-0">
      <tbody>
        <tr>
          <td style={{ border: "2px solid #FFFFFF" }}></td>

          {currentHole === 1 ? (
            <td
              onClick={onLeftNav}
              style={{
                background: "#FFFFFF",
                color: "#FFFFFF",
                textAlign: "center",
                verticalAlign: "middle",
                border: "2px solid #FFFFFF",
                fontWeight: "bold",
                fontSize: "30px",
                padding: "0px 0px",
                width: "10px",
              }}
            ></td>
          ) : (
            <td
              onClick={onLeftNav}
              style={{
                // background:'#4D81F4',
                // color:'#FFFFFF',
                textAlign: "center",
                verticalAlign: "middle",
                border: "2px solid #FFFFFF",
                fontWeight: "bold",
                fontSize: "30px",
                padding: "0px 0px",
                width: "10px",
              }}
            >
              {/* {'<<'} */}
              <img src={scorenav_left} alt="left" />
            </td>
          )}
          <td
            onClick={toggleSummary}
            style={{
              backgroundColor: backgroundColor,
              color: textColor,
              fontWeight: "bold",
              textAlign: "center",
              verticalAlign: "middle",
              border: "2px solid #FFFFFF",
            }}
            width="200px"
          >
            {showSummary ? "Summary" : "Hole " + currentHole}
          </td>
          <td
            onClick={onRightNav}
            style={{
              textAlign: "center",
              border: "2px solid #FFFFFF",
              fontWeight: "bold",
              fontSize: "30px",
              padding: "0px 0px",
              width: "10px",
            }}
          >
            {/* {'>>'} */}
            <img src={scorenav_right} alt="right" />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default ScorecardNavbarTop;
