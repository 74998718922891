import React from "react";
import ScorecardThrowsRowScoreBoxCol from "./ScorecardThrowsRowScoreBoxCol";
import { Row } from "react-bootstrap";

function ScorecardThrowsRowScoreBoxRow({
  throwScore,
  throwScoresId,
  handleThrowScoreChange,
  gameId,
  throwId,
  isRetryingThrowScore,
  isUpdatingThrowScore,
}) {
  return (
    <Row noGutters>
      <ScorecardThrowsRowScoreBoxCol
        throwScoresId={throwScoresId}
        colSkelaton={"0"}
        throwScore={throwScore}
        handleThrowScoreChange={handleThrowScoreChange}
        gameId={gameId}
        throwId={throwId}
        isRetryingThrowScore={isRetryingThrowScore}
        isUpdatingThrowScore={isUpdatingThrowScore}
      />
      <ScorecardThrowsRowScoreBoxCol
        throwScoresId={throwScoresId}
        colSkelaton={"1"}
        throwScore={throwScore}
        handleThrowScoreChange={handleThrowScoreChange}
        gameId={gameId}
        throwId={throwId}
        isRetryingThrowScore={isRetryingThrowScore}
        isUpdatingThrowScore={isUpdatingThrowScore}
      />
      <ScorecardThrowsRowScoreBoxCol
        throwScoresId={throwScoresId}
        colSkelaton={"2"}
        throwScore={throwScore}
        handleThrowScoreChange={handleThrowScoreChange}
        gameId={gameId}
        throwId={throwId}
        isRetryingThrowScore={isRetryingThrowScore}
        isUpdatingThrowScore={isUpdatingThrowScore}
      />
    </Row>
  );
}

export default ScorecardThrowsRowScoreBoxRow;
