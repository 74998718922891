import React, { useEffect } from "react";
import { useState } from "react";
import {
  insertScoreEdit,
  updateHoleScore,
  updateThrowScore,
  getLayoutHoleId,
  createHoleScore,
  createThrowScore,
  insertThrowScoreEdit,
} from "../util/db";
import { useAuth } from "../util/auth.js";
import { useRouter } from "../util/router";
import ScorecardRowPlayerTd from "./ScorecardRowPlayerTd.js";
import ScorecardRowHoleinfoToast from "./ScorecardRowHoleinfoToast.js";
import ScorecardThrowsRowScoreboxTd from "./ScorecardThrowsRowScoreboxTd.js";
import ScorecardThrowsRowScoreBox from "./ScorecardThrowsRowScoreBox.js";

function ScorecardThrowsRow({
  player,
  currentHole,
  isComplete,
  showTotals,
  isRetryingHole,
  setIsRetryingHole,
  isRetryingThrowOne,
  setIsRetryingThrowOne,
  isRetryingThrowTwo,
  setIsRetryingThrowTwo,
  throwScoringModeId,
}) {
  //i believe this filter is now unnecessary, I sorted the data already in throws_scorecard
  const currentHoleHoleScore = player.event_entries.hole_scores.filter(
    (hole_score) => hole_score.layout_holes.hole_order === currentHole
  )[0];
  const router = useRouter();
  const auth = useAuth();
  const [showHoleInfo, setShowHoleInfo] = useState(false);
  const [dbResponseScore, setDbResponseScore] = useState(null);
  const [thisScore, setThisScore] = useState();
  const [thisScoreAdv, setThisScoreAdv] = useState();
  const [isCreating, setIsCreating] = useState(false);

  const [throwOneScore, setThrowOneScore] = useState(
    currentHoleHoleScore?.throw_scores?.[0]?.throw_score ?? null
  );
  const [throwTwoScore, setThrowTwoScore] = useState(
    currentHoleHoleScore?.throw_scores?.[1]?.throw_score ?? null
  );
  const [throwOneThrowScoresId, setThrowOneThrowScoresId] = useState(
    currentHoleHoleScore?.throw_scores?.[0]?.id ?? null
  );
  const [throwTwoThrowScoresId, setThrowTwoThrowScoresId] = useState(
    currentHoleHoleScore?.throw_scores?.[1]?.id ?? null
  );
  const [isUpdatingScore, setIsUpdatingScore] = useState(false);

  const [isUpdatingThrowOne, setIsUpdatingThrowOne] = useState(false);
  const [isUpdatingThrowTwo, setIsUpdatingThrowTwo] = useState(false);

  const toggleHoleInfo = () => setShowHoleInfo(!showHoleInfo);

  const event_entries_id = player.event_entries_id;
  const hole_scores_id = getHolescoreId();
  const uid = auth.user ? auth.user.uid : undefined;

  const gameId = player.event_entries.events.game_id;

  // console.log(player);

  //calculate this players total score. Sum of all hole_scores
  let total = 0;
  player.event_entries.hole_scores.forEach((score, index) => {
    total += score.score;
  });

  let total_adv = 0;
  player.event_entries.hole_scores.forEach((score, index) => {
    total_adv += score.hole_score_adv;
  });

  //function to view hole score edits by redirecting to /holescoreedits
  function onViewEdits() {
    //redirect to /holescoreedits
    router.push(
      `/holescoreedits?hole=${hole_scores_id}&event=${player.event_entries.event_id}`
    );
  }

  //function to log hole_score_edit into database
  function logHoleScoreEdit(hole_score_id, newScore, uid, hole_score_adv) {
    insertScoreEdit({
      hole_scores_id: hole_score_id,
      score: newScore,
      editor_id: uid,
      hole_score_adv: hole_score_adv,
    });
  }

  function logThrowScoreEdit(throwScoresId, newScore, uid, isSuccessful) {
    insertThrowScoreEdit({
      throw_scores_id: throwScoresId,
      throw_score: newScore,
      editor_id: uid,
      is_successful: isSuccessful,
    });
  }

  // function for if player trys to click the score column on a locked scorecard
  function onClickLockedScore() {
    alert(
      "This scorecard is locked. Please contact the event organizer to make changes."
    );
  }

  // function to get the hole score id for the current hole
  function getHolescoreId() {
    let hole_scores_id = null;
    player.event_entries.hole_scores.map((hole_score, index) => {
      if (hole_score.layout_holes.hole_order === currentHole) {
        hole_scores_id = hole_score.hole_scores_id;
      }
      return hole_scores_id;
    });
    return hole_scores_id;
  }

  // function to update the score on the current hole if the player clicks the score column
  function onClickScore() {
    // setIsUpdatingScore(true);
    // // if currentHoleHoleScore.score is null, set holescore to 5 and throw scores to 2
    // // if currentHoleHoleScore.score is 5, set holeScore to 0 and throw scores to 0
    // // else if curcurrentHoleHoleScore.score is 0 set holeScore to null and throw scores to null
    // let newHoleScore = null;
    // let newThrowOneScore = null;
    // let newThrowTwoScore = null;
    // //if there is no hole score
    // if (currentHoleHoleScore.score === null) {
    //   setIsUpdatingScore(true);
    //   newHoleScore = 5;
    //   newThrowOneScore = 2;
    //   newThrowTwoScore = 2;
    //   // update throw score object
    //   currentHoleHoleScore.throw_scores[0].throw_score = newThrowOneScore;
    //   currentHoleHoleScore.throw_scores[1].throw_score = newThrowTwoScore;
    //   //update hole score in currentHoleHoleScore
    //   currentHoleHoleScore.score = newHoleScore;
    //   //update throw scores in database
    //   updateThrowScore(throwOneThrowScoresId, newThrowOneScore)
    //     .then((res) => {
    //       // console.log('Throw score updated:')
    //     })
    //     .catch((err) => {
    //       console.error("Error updating throw score:", err);
    //     });
    //   updateThrowScore(throwTwoThrowScoresId, newThrowTwoScore)
    //     .then((res) => {
    //       // console.log('Throw score updated:')
    //     })
    //     .catch((err) => {
    //       console.error("Error updating throw score:", err);
    //     });
    //   //update hole score in database
    //   updateHoleScore(hole_scores_id, event_entries_id, {
    //     score: newHoleScore,
    //   }).then((res) => {
    //     setDbResponseScore(res[0].score);
    //     setIsUpdatingScore(false);
    //   });
    //   //update throw scores in state
    //   setThrowOneScore(newThrowOneScore);
    //   setThrowTwoScore(newThrowTwoScore);
    //   setThisScore(newHoleScore);
    //   //if the hole score is 5
    // } else if (currentHoleHoleScore.score === 5) {
    //   setIsUpdatingScore(true);
    //   newHoleScore = 0;
    //   newThrowOneScore = 0;
    //   newThrowTwoScore = 0;
    //   // update throw score object
    //   currentHoleHoleScore.throw_scores[0].throw_score = newThrowOneScore;
    //   currentHoleHoleScore.throw_scores[1].throw_score = newThrowTwoScore;
    //   //update hole score in currentHoleHoleScore
    //   currentHoleHoleScore.score = newHoleScore;
    //   //update throw scores in database
    //   updateThrowScore(throwOneThrowScoresId, newThrowOneScore)
    //     .then((res) => {
    //       // console.log('Throw score updated:')
    //     })
    //     .catch((err) => {
    //       console.error("Error updating throw score:", err);
    //     });
    //   updateThrowScore(throwTwoThrowScoresId, newThrowTwoScore)
    //     .then((res) => {
    //       // console.log('Throw score updated:')
    //     })
    //     .catch((err) => {
    //       console.error("Error updating throw score:", err);
    //     });
    //   //update hole score in database
    //   updateHoleScore(hole_scores_id, event_entries_id, {
    //     score: newHoleScore,
    //   }).then((res) => {
    //     setDbResponseScore(res[0].score);
    //     setIsUpdatingScore(false);
    //   });
    //   //update throw scores in state
    //   setThrowOneScore(newThrowOneScore);
    //   setThrowTwoScore(newThrowTwoScore);
    //   setThisScore(newHoleScore);
    //   //if the hole score is 0
    // } else if (currentHoleHoleScore.score === 0) {
    //   setIsUpdatingScore(true);
    //   newHoleScore = null;
    //   newThrowOneScore = null;
    //   newThrowTwoScore = null;
    //   // update throw score object
    //   currentHoleHoleScore.throw_scores[0].throw_score = newThrowOneScore;
    //   currentHoleHoleScore.throw_scores[1].throw_score = newThrowTwoScore;
    //   //update hole score in currentHoleHoleScore
    //   currentHoleHoleScore.score = newHoleScore;
    //   //update throw scores in database
    //   updateThrowScore(throwOneThrowScoresId, newThrowOneScore)
    //     .then((res) => {
    //       // console.log('Throw score updated:')
    //     })
    //     .catch((err) => {
    //       console.error("Error updating throw score:", err);
    //     });
    //   updateThrowScore(throwTwoThrowScoresId, newThrowTwoScore)
    //     .then((res) => {
    //       // console.log('Throw score updated:')
    //     })
    //     .catch((err) => {
    //       console.error("Error updating throw score:", err);
    //     });
    //   //update hole score in database
    //   updateHoleScore(hole_scores_id, event_entries_id, {
    //     score: newHoleScore,
    //   }).then((res) => {
    //     setDbResponseScore(res[0].score);
    //     setIsUpdatingScore(false);
    //   });
    //   //update throw scores in state
    //   setThrowOneScore(newThrowOneScore);
    //   setThrowTwoScore(newThrowTwoScore);
    //   setThisScore(newHoleScore);
    // }
  }

  // function holeScoresIdIsNull() {
  //   return hole_scores_id === null;
  // }

  //useEffect to set thisScore to the current hole score
  useEffect(() => {
    function loadHoleScore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      );
      //if this_hole_score[0] is not undefined, return it
      if (this_hole_score[0] !== undefined) {
        // return this_hole_score[0].score
        setThisScore(this_hole_score[0].score);
      }
    }

    function loadHoleScoreAdv() {
      const this_hole_score_adv = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      );

      if (this_hole_score_adv[0] !== undefined) {
        setThisScoreAdv(this_hole_score_adv[0].hole_score_adv);
      }
    }

    function loadThrowOneScore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      );
      //if this_hole_score[0] is not undefined, return it
      if (this_hole_score[0] !== undefined) {
        // return this_hole_score[0].throw_scores[0].throw_score
        setThrowOneScore(this_hole_score[0].throw_scores[0].throw_score);
        setThrowOneThrowScoresId(this_hole_score[0].throw_scores[0].id);
      }
    }

    function loadThrowTwoScore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      );

      //if this_hole_score[0] is not undefined, return it
      if (this_hole_score[0] !== undefined) {
        // return this_hole_score[0].throw_scores[1].throw_score
        setThrowTwoScore(this_hole_score[0].throw_scores[1].throw_score);
        setThrowTwoThrowScoresId(this_hole_score[0].throw_scores[1].id);
      }
    }

    //if player.event_entries.holes_scores is not empty, load scores into state
    if (player.event_entries.hole_scores.length !== 0) {
      // setThisScore(loadHoleScore())
      loadHoleScore();
      loadHoleScoreAdv();
    } else {
      console.log("error, no hole score for ", currentHole);
    }

    loadThrowOneScore();
    loadThrowTwoScore();

    //reset dbResponseScore to null
    setDbResponseScore(null);

    // eslint-disable-next-line
  }, [currentHole]);

  async function updateHoleScoreWithRetry(
    holeScoresId,
    eventEntriesId,
    score,
    hole_score_adv
  ) {
    updateHoleScore(holeScoresId, eventEntriesId, {
      score: score,
      hole_score_adv: hole_score_adv,
    })
      .then((res) => {
        setDbResponseScore(res[0].score);
        setIsUpdatingScore(false);
        setIsRetryingHole(false);
      })
      .catch((err) => {
        console.error("Error updating hole score:", err);
        setIsRetryingHole(true);
        setTimeout(() => {
          updateHoleScoreWithRetry(
            holeScoresId,
            eventEntriesId,
            score,
            hole_score_adv
          );
        }, 5000);
      });
  }

  async function updateThrowScoreWithRetry(
    throwScoresId,
    newThrowScore,
    throwNumber
  ) {
    updateThrowScore(throwScoresId, newThrowScore)
      .then((res) => {
        // console.log('Throw score updated:')
        if (throwNumber === 1) {
          setIsUpdatingThrowOne(false);
          setIsRetryingThrowOne(false);
        } else if (throwNumber === 2) {
          setIsUpdatingThrowTwo(false);
          setIsRetryingThrowTwo(false);
        }
        logThrowScoreEdit(throwScoresId, newThrowScore, uid, true);
      })
      .catch((err) => {
        console.error("Error updating throw score:", err);
        logThrowScoreEdit(throwScoresId, newThrowScore, uid, false);
        if (throwNumber === 1) {
          setIsRetryingThrowOne(true);
        } else if (throwNumber === 2) {
          setIsRetryingThrowTwo(true);
        }
        setTimeout(() => {
          updateThrowScoreWithRetry(throwScoresId, newThrowScore, throwNumber);
        }, 5000);
      });
  }

  //when the throw score changes, this function is called to update the throw score in the database and state
  function handleThrowScoreChange(throwScoresId, newThrowScore, throwNumber) {
    setIsUpdatingScore(true);

    if (throwNumber === 1) {
      setIsUpdatingThrowOne(true);
    } else if (throwNumber === 2) {
      setIsUpdatingThrowTwo(true);
    }

    // update throw score object
    currentHoleHoleScore.throw_scores[throwNumber - 1].throw_score =
      newThrowScore;

    //update throw score in database
    // updateThrowScore(throwScoresId, newThrowScore)
    //   .then((res) => {
    //     // console.log('Throw score updated:')
    //     if (throwNumber === 1) {
    //       setIsUpdatingThrowOne(false);
    //     } else if (throwNumber === 2) {
    //       setIsUpdatingThrowTwo(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.error("Error updating throw score:", err);
    //   });
    updateThrowScoreWithRetry(throwScoresId, newThrowScore, throwNumber);

    //update throw score state
    if (throwNumber === 1) {
      setThrowOneScore(newThrowScore);
    } else if (throwNumber === 2) {
      setThrowTwoScore(newThrowScore);
    }

    let newHoleScore = calculateHoleScore();
    let newHoleScoreAdv = calculateHoleScoreAdv();

    ////update hole score in currentHoleHoleScore
    currentHoleHoleScore.score = newHoleScore;
    currentHoleHoleScore.hole_score_adv = newHoleScoreAdv;

    //update the hole score in the database
    // updateHoleScore(hole_scores_id, event_entries_id, {
    //   score: newHoleScore,
    // }).then((res) => {
    //   setDbResponseScore(res[0].score);
    //   setIsUpdatingScore(false);
    // });
    updateHoleScoreWithRetry(
      hole_scores_id,
      event_entries_id,
      newHoleScore,
      newHoleScoreAdv
    );

    // update hole score in state
    setThisScore(newHoleScore);

    // update hole score adv in state
    setThisScoreAdv(newHoleScoreAdv);

    //log edit to database
    logHoleScoreEdit(hole_scores_id, newHoleScore, uid, newHoleScoreAdv);
  }

  // function to calculate the hole score by adding the two throw scores and add bonus point if necessary
  function calculateHoleScore() {
    let throwOneScore_ = currentHoleHoleScore.throw_scores[0].throw_score;
    let throwTwoScore_ = currentHoleHoleScore.throw_scores[1].throw_score;
    let newHoleScore =
      (throwOneScore_ !== null ? Number(throwOneScore_) : 0) +
      (throwTwoScore_ !== null ? Number(throwTwoScore_) : 0);

    // if newscore === 4, set it to 5
    if (newHoleScore === 4) {
      newHoleScore = 5;
    }

    return newHoleScore;
  }

  function calculateHoleScoreAdv() {
    const thisThrowValueIndex = player.event_entries.hole_scores.findIndex(
      (hole_score) => hole_score.layout_holes.hole_order === currentHole
    );

    if (player) {
      const make1_value =
        player.throw_score_values[thisThrowValueIndex].make1_value;
      const make2_value =
        player.throw_score_values[thisThrowValueIndex].make2_value;
      const tap_value =
        player.throw_score_values[thisThrowValueIndex].tap_value;
      const bonus_value =
        player.throw_score_values[thisThrowValueIndex].bonus_value;

      let throwOneScore_ = currentHoleHoleScore.throw_scores[0].throw_score;
      let throwTwoScore_ = currentHoleHoleScore.throw_scores[1].throw_score;

      let throwOneScore_Adv =
        throwOneScore_ === 0
          ? 0
          : throwOneScore_ === 1
          ? tap_value
          : throwOneScore_ === 2
          ? make1_value
          : null;

      let throwTwoScore_Adv =
        throwTwoScore_ === 0
          ? 0
          : throwTwoScore_ === 1
          ? tap_value
          : throwTwoScore_ === 2
          ? make2_value
          : null;

      let newHoleScoreAdv =
        (throwOneScore_Adv !== null ? Number(throwOneScore_Adv) : 0) +
        (throwTwoScore_Adv !== null ? Number(throwTwoScore_Adv) : 0);

      //if both throwOneScore and throwTwoScore are 2, set newHoleScoreAdv to newholescoreadv+bonus_value
      if (throwOneScore_ === 2 && throwTwoScore_ === 2) {
        newHoleScoreAdv = newHoleScoreAdv + bonus_value;
      }

      return newHoleScoreAdv;
    }
  }
  // Create a hole score if it doesn't exist (error state)
  useEffect(() => {
    if (holeScoresIdIsNull() && !isCreating) {
      setIsCreating(true);

      getLayoutHoleId(
        player.event_entries.event_divisions.layout_id,
        currentHole
      )
        .then((res) => {
          const data = {
            event_entries_id: event_entries_id,
            layout_holes_id: res.layout_holes_id,
          };

          createHoleScore(data)
            .then((holeScoreData) => {
              // log the response
              console.log("created hole score", res);

              // Create throw scores for the hole score
              const numThrows = 2; // Number of throws per hole
              const throwScorePromises = [];
              for (
                let throwNumber = 1;
                throwNumber <= numThrows;
                throwNumber++
              ) {
                const dataThrowScore = {
                  hole_scores_id: holeScoreData[0].hole_scores_id,
                  throw_number: throwNumber,
                };
                throwScorePromises.push(createThrowScore(dataThrowScore));
              }

              // Wait for all throw scores to be created
              Promise.all(throwScorePromises)
                .then(() => {
                  console.log(
                    "created throw scores for hole score",
                    holeScoreData
                  );
                  setIsCreating(false); // Reset the flag after creation
                  window.location.reload();
                })
                .catch((err) => {
                  console.error("Error creating throw scores:", err);
                  setIsCreating(false); // Reset the flag in case of error
                });
            })
            .catch(() => {
              // console.error('Error creating hole score:', err);
              setIsCreating(false); // Reset the flag in case of error
            });
        })
        .catch(() => {
          // console.error('Error getting layout hole ID:', err);
          setIsCreating(false); // Reset the flag in case of error
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hole_scores_id, currentHole, event_entries_id]);

  function holeScoresIdIsNull() {
    return hole_scores_id === null;
  }

  return (
    <>
      <tr>
        <ScorecardRowPlayerTd
          playerName={player.event_entries.users_public.name}
          showTotals={showTotals}
          total={total}
          toggleHoleInfo={toggleHoleInfo}
          totalAdv={total_adv}
          throwScoringModeId={throwScoringModeId}
        />
        <ScorecardThrowsRowScoreBox
          throwId={1}
          isComplete={isComplete}
          gameId={gameId}
          currentHoleHoleScore={currentHoleHoleScore}
          throwScore={throwOneScore}
          handleThrowScoreChange={handleThrowScoreChange}
          throwScoresId={throwOneThrowScoresId}
          isRetryingThrowScore={isRetryingThrowOne}
          isUpdatingThrowScore={isUpdatingThrowOne}
        />
        <ScorecardThrowsRowScoreBox
          throwId={2}
          isComplete={isComplete}
          gameId={gameId}
          currentHoleHoleScore={currentHoleHoleScore}
          throwScore={throwTwoScore}
          handleThrowScoreChange={handleThrowScoreChange}
          throwScoresId={throwTwoThrowScoresId}
          isRetryingThrowScore={isRetryingThrowTwo}
          isUpdatingThrowScore={isUpdatingThrowTwo}
        />
        {/* Total Score box for legacy score */}
        <ScorecardThrowsRowScoreboxTd
          isUpdatingScore={isUpdatingScore}
          isComplete={isComplete}
          thisScore={thisScore}
          hole_scores_id={hole_scores_id}
          dbResponseScore={dbResponseScore}
          onClickScore={onClickScore}
          onClickLockedScore={onClickLockedScore}
          isRetryingHole={isRetryingHole}
        />
        {/* Total score box for + scoring */}
        {throwScoringModeId > 1 && (
          <ScorecardThrowsRowScoreboxTd
            isUpdatingScore={isUpdatingScore}
            isComplete={isComplete}
            thisScore={thisScoreAdv}
            hole_scores_id={hole_scores_id}
            dbResponseScore={dbResponseScore}
            onClickScore={onClickScore}
            onClickLockedScore={onClickLockedScore}
            isRetryingHole={isRetryingHole}
          />
        )}
      </tr>
      <tr>
        <ScorecardRowHoleinfoToast
          player={player}
          currentHole={currentHole}
          showHoleInfo={showHoleInfo}
          toggleHoleInfo={toggleHoleInfo}
          onViewEdits={onViewEdits}
        />
      </tr>
    </>
  );
}

export default ScorecardThrowsRow;
