import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import {
  usePayoutMethods,
  useDivisions,
  createEvent,
  createEventDivisions,
  useCourses,
  useGames,
  useThrowScoringModes,
} from "../util/db";
import { useLayouts } from "../util/db";
import EventCreateFormStep1 from "./EventCreateFormStep1";
import EventCreateFormStep2 from "./EventCreateFormStep2";
// import EventCreateFormStep4 from "./EventCreateFormStep4";
// import EventCreateFormStep3 from "./EventCreateFormStep3";
import EventCreateFormStep5 from "./EventCreateFormStep5";
import EventCreateFormStep6 from "./EventCreateFormStep6";
import EventCreateFormStep7 from "./EventCreateFormStep7";
import EventCreateFormFooter from "./EventCreateFormFooter";
import EventCreateFormNav from "./EventCreateFormNav";
import EventCreateFormAlertNoName from "./EventCreateFormAlertNoName";
import EventCreateFormAlertNoDivisions from "./EventCreateFormAlertNoDivisions";

function EventCreateForm({ uid }) {
  const numSteps = 5;
  let todaysDate = new Date().toISOString().slice(0, 10);

  const [formDate, setFormDate] = useState(todaysDate);
  const [formName, setFormName] = useState("");
  const [formIsGolfScoring, setFormIsGolfScoring] = useState(false);
  const [formGame, setFormGame] = useState(1);
  const [formScoringMode, setFormScoringMode] = useState(2);
  const [step, setStep] = useState(1);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(1);
  const [selectedVenueName, setSelectedVenueName] = useState("Modist");
  const [isCreatingEvent, setIsCreatingEvent] = useState(false);

  //get available divisions useDivisions
  const {
    data: divisions,
    status: divisionsStatus,
    // error: divisionsError
  } = useDivisions();

  //get available payout methods usePayoutMethods
  const {
    data: payoutMethods,
    // status: payoutMethodsStatus,
    // error: payoutMethodsError
  } = usePayoutMethods();

  //get available layouts useLayouts
  const {
    data: layouts,
    // status: layoutsStatus,
    // error: layoutsError
  } = useLayouts();

  const {
    data: courses,
    status: coursesStatus,
    // error: coursesError
  } = useCourses();

  const {
    data: games,
    // status: gamesStatus,
    // error: gamesError
  } = useGames();

  const {
    data: scoringModes,
    // status: scoringModesStatus,
    // error: scoringModesError,
  } = useThrowScoringModes();

  //create event_divisions data array
  let eventDivisionsData = [];

  //handle submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsCreatingEvent(true);

    //create event data array
    let eventData = {
      name: formName,
      date: formDate,
      is_rebuys: true,
      scoring_order_golf: formIsGolfScoring,
      td_id: uid,
      course_id: selectedVenue,
      game_id: formGame,
      throw_scoring_mode_id: formScoringMode,
    };

    selectedDivisions.forEach((division) => {
      var totalCost =
        parseInt(division.weekly_payout, 10) +
        parseInt(division.series_payout, 10) +
        parseInt(division.event_costs, 10);

      eventDivisionsData.push({
        division_id: division.division_id,
        division_cost: totalCost,
        series_payout_rake_per_player: division.series_payout,
        division_rake_per_player: division.event_costs,
        layout_id: division.layout_id,
      });
    });

    //add eventData to events table. get event_id for eventDivisionsData, wait for response, then
    createEvent(eventData).then((eventResponse) => {
      //add event to eventDivisionsData
      eventDivisionsData.forEach((eventDivision) => {
        eventDivision.event_id = eventResponse[0].id;
      });

      //add eventDivisions to event_divisions table
      createEventDivisions(eventDivisionsData)
        .then((eventDivisionsResponse) => {
          // redirect to event_manage page
          window.location.href = "/event_manage?event=" + eventResponse[0].id;
        })
        .finally(() => {
          setIsCreatingEvent(false);
        });
    });
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleFormDateChange = (e) => {
    setFormDate(e.target.value);
  };

  const handleFormNameChange = (e) => {
    setFormName(e.target.value);
  };

  const handleFormIsGolfScoringChange = (e) => {
    setFormIsGolfScoring(e.target.checked);
  };

  const handleFormGameChange = (e) => {
    setFormGame(parseInt(e.target.value, 10));
  };

  const handleFormScoringModeChange = (e) => {
    setFormScoringMode(parseInt(e.target.value, 10));
  };

  return (
    <Container>
      <Form>
        {step === 1 && (
          <EventCreateFormStep1
            games={games}
            formName={formName}
            formDate={formDate}
            formGame={formGame}
            formIsGolfScoring={formIsGolfScoring}
            handleFormNameChange={handleFormNameChange}
            handleFormDateChange={handleFormDateChange}
            handleFormGameChange={handleFormGameChange}
            handleFormIsGolfScoringChange={handleFormIsGolfScoringChange}
            scoringModes={scoringModes}
            formScoringMode={formScoringMode}
            handleFormScoringModeChange={handleFormScoringModeChange}
          />
        )}
        {step === 2 && (
          <EventCreateFormStep2
            divisions={divisions}
            divisionsStatus={divisionsStatus}
            selectedDivisions={selectedDivisions}
            setSelectedDivisions={setSelectedDivisions}
          />
        )}

        {/* <EventCreateFormStep3
            selectedDivisions={selectedDivisions}
            setSelectedDivisions={setSelectedDivisions}
            payoutMethods={payoutMethods}
          /> */}
        {step === 3 && (
          <EventCreateFormStep5
            selectedDivisions={selectedDivisions}
            setSelectedDivisions={setSelectedDivisions}
            courses={courses}
            coursesStatus={coursesStatus}
            selectedVenue={selectedVenue}
            setSelectedVenue={setSelectedVenue}
            setSelectedVenueName={setSelectedVenueName}
          />
        )}

        {/* <EventCreateFormStep4
            selectedDivisions={selectedDivisions}
            setSelectedDivisions={setSelectedDivisions}
            payoutMethods={payoutMethods}
          /> */}
        {step === 4 && (
          <EventCreateFormStep6
            layouts={layouts}
            selectedDivisions={selectedDivisions}
            setSelectedDivisions={setSelectedDivisions}
          />
        )}

        {step === 5 && (
          <EventCreateFormStep7
            formName={formName}
            formDate={formDate}
            formIsGolfScoring={formIsGolfScoring}
            formGame={formGame}
            games={games}
            selectedVenueName={selectedVenueName}
            layouts={layouts}
            payoutMethods={payoutMethods}
            selectedDivisions={selectedDivisions}
            setSelectedDivisions={setSelectedDivisions}
          />
        )}
        {/* 
        {step === 6 && (

        )} */}

        {/* {step === 7 && (

        )} */}

        <EventCreateFormNav
          step={step}
          numSteps={numSteps}
          formName={formName}
          selectedDivisions={selectedDivisions}
          handleSubmit={handleSubmit}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          isCreatingEvent={isCreatingEvent}
        />

        {step === 1 && formName === "" && <EventCreateFormAlertNoName />}

        {step === 2 && selectedDivisions.length === 0 && (
          <EventCreateFormAlertNoDivisions />
        )}

        <hr />

        <EventCreateFormFooter step={step} numSteps={numSteps} />
      </Form>
    </Container>
  );
}

export default EventCreateForm;
