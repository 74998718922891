import React, { useState } from "react";
import { Button } from "react-bootstrap";
//from db.js
import { updateUserAccessLevel } from "../util/db";

function AdminPagePlayersRowAdmin({ id, user_access_level_id }) {
  const [isEditing, setIsEditing] = useState(false);
  const [userAccessLevel, setUserAccessLevel] = useState(user_access_level_id);

  const [userAccessLevelName, setUserAccessLevelName] = useState(
    user_access_level_id === 1
      ? "User"
      : user_access_level_id === 2
      ? "Moderator"
      : user_access_level_id === 3
      ? "Administrator"
      : "Unknown"
  );
  // if userAccessLevel is 1, then set to "User"
  // if userAccessLevel is 2, then set to "Moderator"
  // if userAccessLevel is 3, then set to "Administrator"

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    // update the user's access level
    updateUserAccessLevel(id, userAccessLevel).then((res) => {
      setIsEditing(false);
      setUserAccessLevelName(
        userAccessLevel === 1
          ? "User"
          : userAccessLevel === 2
          ? "Moderator"
          : userAccessLevel === 3
          ? "Administrator"
          : "Unknown"
      );
    });

    setIsEditing(false);
  };

  const handleCancelClick = () => {
    // setIsAdmin(is_admin);
    setIsEditing(false);
  };

  return (
    <td>
      {isEditing ? (
        <>
          {/* dropdown box. Options user(1),moderator(2),admin(3) */}
          <select
            value={userAccessLevel}
            onChange={(e) => {
              setUserAccessLevel(e.target.value);
            }}
          >
            <option value="1">User</option>
            <option value="2">Moderator</option>
            <option value="3">Administrator</option>
          </select>

          <Button
            style={{ background: "#4D81F4", fontWeight: "bold", border: "0" }}
            onClick={handleSaveClick}
            className="mt-2"
          >
            Save
          </Button>
          <Button
            style={{ background: "#4D81F4", fontWeight: "bold", border: "0" }}
            onClick={handleCancelClick}
            className="mt-2"
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          {userAccessLevelName}
          <span
            onClick={handleEditClick}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          >
            ✏️
          </span>
        </>
      )}
    </td>
  );
}

export default AdminPagePlayersRowAdmin;
