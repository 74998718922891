import React from "react";
import DisplayScoreboard from "./DisplayScoreboard";
import DisplayScoreboardByDivision from "./DisplayScoreboardByDivision";

function DisplayLeaderboard({
  eventName,
  eventId,
  scoringOrderGolf,
  isEventComplete,
  throwScoringModeId,
}) {
  //state for checkbox
  const [checked, setChecked] = React.useState(true);

  //function to handle checkbox click
  // const onClickCheckbox = () => {
  //   setChecked(!checked);
  // };

  return (
    <>
      {/* <Form className="text-right">
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          label="Sort by Division"
          onChange={onClickCheckbox}
          checked={checked}
          className="mb-3"
        />
      </Form> */}
      {checked ? (
        <DisplayScoreboardByDivision
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title={eventName}
          subtitle=""
          eventId={eventId}
          scoringOrderGolf={scoringOrderGolf}
          isEventComplete={isEventComplete}
          throwScoringModeId={throwScoringModeId}
        />
      ) : (
        <DisplayScoreboard
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title={eventName}
          subtitle=""
          eventId={eventId}
          scoringOrderGolf={scoringOrderGolf}
          isEventComplete={isEventComplete}
          throwScoringModeId={throwScoringModeId}
        />
      )}
    </>
  );
}

export default DisplayLeaderboard;
