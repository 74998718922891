import React, { useEffect, useState, useMemo } from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import EventSideGameSection from "../components/EventSideGameSection";
import { useAuth } from "../util/auth";
import { useEventName, useEventTdId } from "../util/db";
import EventTdSection from "../components/EventTdSection";
import CtaSection from "../components/CtaSection";
import HrCustom from "../components/HrCustom";
import { Container } from "react-bootstrap";
import {
  useEventEntriesByEventUser,
  useGroupScorecardsByEventUser,
  useForumPostsNotHidden,
} from "../util/db";
import "../components/SectionHeader.scss";
import EventPageTabs from "../components/EventPageTabs";
import EventPageHeader from "../components/EventPageHeader";

function EventPage(props) {
  const router = useRouter();
  const auth = useAuth();
  const eventId = router.query.event;
  const uid = auth.user?.uid || "";
  const [isEventComplete, setIsEventComplete] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState();
  const [eventCourse, setEventCourse] = useState("");
  const [eventCourseId, setEventCourseId] = useState();
  const [eventGameName, setEventGameName] = useState("");
  const [scoringOrderGolf, setScoringOrderGolf] = useState(null);
  const [tdId, setTdId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [canCheckIn, setCanCheckIn] = useState(true);
  const [isIncompleteCards, setIsIncompleteCards] = useState(false);
  const [isCheckedInAlready, setIsCheckedInAlready] = useState(false);
  const [gameId, setGameId] = useState();
  const [throwScoringModeId, setThrowScoringModeId] = useState(1);

  const { data: posts } = useForumPostsNotHidden(eventId);
  const { data: entries } = useEventEntriesByEventUser(eventId, uid);
  const { data: scorecards, status: scorecardsStatus } =
    useGroupScorecardsByEventUser(eventId, uid);
  const { data: items, status: itemsStatus } = useEventName(eventId);
  const { data: itemsTd, status: itemsStatusTd } = useEventTdId(eventId);

  useEffect(() => {
    //if the scorecard has loaded, and player is on a scorecard
    if (scorecardsStatus !== "loading" && scorecards) {
      //loop through scorecards
      scorecards.forEach((scorecard) => {
        //if any scorecard is not complete, set is incomplete cards to true (to trigger display of card in progress) and can check in to false
        if (!scorecard.group_scorecard.is_complete) {
          setIsIncompleteCards(true);
          setCanCheckIn(false);
        }
      });
    }

    //if there are entries, and already at least one scorecard
    //check if the number of entries is not equal to the number of scorecards (indicates not all entries have a scorecard)
    //that means they are already checked in, and should not be allowed to check in again
    if (entries && scorecards && entries.length !== scorecards.length) {
      setIsCheckedInAlready(true);
      setCanCheckIn(false);
    }
  }, [scorecards, entries, scorecardsStatus]);

  useEffect(() => {
    setIsLoggedIn(uid !== "");
  }, [uid]);

  useEffect(() => {
    if (itemsStatus !== "loading" && items?.length > 0) {
      const item = items[0];
      setEventName(item.name);
      setEventCourse(item.courses.course_name);
      setEventCourseId(item.course_id);
      setScoringOrderGolf(item.scoring_order_golf);
      setEventGameName(item.games.name);
      setIsEventComplete(item.is_event_complete);
      setGameId(item.game_id);
      setThrowScoringModeId(item.throw_scoring_mode_id);

      const eventDate = new Date(item.date);
      const utcDate = new Date(
        eventDate.getUTCFullYear(),
        eventDate.getUTCMonth(),
        eventDate.getUTCDate()
      );
      setEventDate(utcDate.toLocaleDateString());
    }
  }, [items, itemsStatus]);

  useEffect(() => {
    if (itemsStatusTd !== "loading" && itemsTd?.length > 0) {
      setTdId(itemsTd[0].td_id);
    }
  }, [itemsTd, itemsStatusTd]);

  const buttonPath = useMemo(
    () => `/auth/signin?next=/event?event=${eventId}`,
    [eventId]
  );

  return (
    <>
      <Meta title={eventName} />
      {!isLoggedIn && (
        <>
          <CtaSection
            bg="primary"
            textColor="light"
            size="sm"
            bgImage=""
            bgImageOpacity={1}
            title="Ready to play?"
            subtitle=""
            buttonText="Log In"
            buttonPath={buttonPath}
          />
          <HrCustom width={"100%"} />
        </>
      )}

      {isLoggedIn && tdId === uid && (
        <Container>
          <EventTdSection eventId={eventId} />
        </Container>
      )}

      <EventPageHeader
        eventName={eventName}
        eventCourse={eventCourse}
        eventDate={eventDate}
        eventGameName={eventGameName}
        eventCourseId={eventCourseId}
      />

      <EventPageTabs
        isIncompleteCards={isIncompleteCards}
        isCheckedInAlready={isCheckedInAlready}
        canCheckIn={canCheckIn}
        eventId={eventId}
        uid={uid}
        isLoggedIn={isLoggedIn}
        isEventComplete={isEventComplete}
        auth={auth}
        gameId={gameId}
        posts={posts}
        eventName={eventName}
        scoringOrderGolf={scoringOrderGolf}
        throwScoringModeId={throwScoringModeId}
      />

      <EventSideGameSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Side Games"
        subtitle=""
        eventId={eventId}
      />
    </>
  );
}

export default EventPage;
