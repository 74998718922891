import { useState } from "react";
import DisplayScoreboardRow from "./DisplayScoreboardRow";
import React from "react";

function DisplayScoreboardByDivisionTable(props) {
  const eventId = props.eventId;
  const throwScoringModeId = props.throwScoringModeId;

  // get scorecard for this evententryid
  const scoreBoard = props.scoreBoard;
  const [
    numHoles,
    // ,setNumHoles
  ] = useState(20); //need to update this to lookup actual hole numbers
  const divisionId = props.divisionId;

  //filter scoreboard by division_id
  const filteredScoreBoard = scoreBoard.filter(
    (item) => item.event_divisions_id === divisionId
  );
  // console.log('filteredscoreboard',filteredScoreBoard)

  //sort filteredScoreBoard by total score(sum of hole_scores.score). Highest score first
  filteredScoreBoard.sort((a, b) => {
    const aTotal = a.hole_scores.reduce((a, b) => {
      return a + b.score;
    }, 0);

    const bTotal = b.hole_scores.reduce((a, b) => {
      return a + b.score;
    }, 0);

    return bTotal - aTotal;
  });

  return (
    <>
      {filteredScoreBoard.map((item, index) => (
        <React.Fragment key={index}>
          {/* {console.log('item',item)} */}
          {/* <thead style={{background:"#2D083F", text:"white"}}> */}
          <DisplayScoreboardRow
            key={index}
            item={item}
            numHoles={numHoles}
            eventId={eventId}
            throwScoringModeId={throwScoringModeId}
          />
        </React.Fragment>
      ))}
    </>
  );
}

export default DisplayScoreboardByDivisionTable;
