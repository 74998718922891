import React from "react";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import { useEventDivisions } from "./../util/db";
import "./DashboardItems.scss";
import EventDivisionsCard from "./EventDivisionsCard";

function EventDivisions(props) {
  // const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useEventDivisions(props.eventId);

  const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      <div className="DashboardItems__card-items-wrapper">
        {(itemsStatus === "loading" || itemsAreEmpty) && (
          <div className="py-5 px-3 align-self-center">
            {itemsStatus === "loading" && (
              <Spinner animation="border" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}

            {itemsStatus !== "loading" && itemsAreEmpty && <>No players yet</>}
          </div>
        )}

        {itemsStatus !== "loading" && items && items.length > 0 && (
          <div>
            {items.map((item, index) => (
              <EventDivisionsCard
                key={index}
                item={item}
                index={index}
                isEventComplete={props.isEventComplete}
                eventId={props.eventId}
                scoringOrderGolf={props.scoringOrderGolf}
                throwScoringModeId={props.throwScoringModeId}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default EventDivisions;
