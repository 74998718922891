import React from "react";
import { ListGroupItem } from "react-bootstrap";

function ScorecardPlayerDnfRow({
  item,
  selectedPlayer,
  setSelectedPlayer,
  setSelectedPlayerGspId,
}) {
  function onUpdateSelectedPlayer() {
    setSelectedPlayer(item.event_entries_id);
    setSelectedPlayerGspId(item.id);
  }

  // console.log(item)
  return selectedPlayer === item.event_entries_id ? (
    // <ListGroupItem className='list-group-item' onClick={() => setSelectedPlayer(item.event_entries_id)} variant='secondary'>
    <ListGroupItem
      className="list-group-item"
      onClick={onUpdateSelectedPlayer}
      variant="secondary"
    >
      {item.event_entries.users_public.name}
    </ListGroupItem>
  ) : (
    // <ListGroupItem className='list-group-item' onClick={() => setSelectedPlayer(item.event_entries_id)}>
    <ListGroupItem className="list-group-item" onClick={onUpdateSelectedPlayer}>
      {item.event_entries.users_public.name}
    </ListGroupItem>
  );
}

export default ScorecardPlayerDnfRow;
