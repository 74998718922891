import React from "react";
import { Alert, Container } from "react-bootstrap";

function ScorecardRetryingMessage({ holeorthrow }) {
  return (
    <Container className="mt-2">
      <Alert variant="warning">
        Please verify you are connected to the internet. {holeorthrow} score not
        submitting.
      </Alert>
    </Container>
  );
}

export default ScorecardRetryingMessage;
