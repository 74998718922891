import React from "react";
import Container from "react-bootstrap/Container";
import { useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "../util/router";
import { useEventTdId } from "../util/db";
import { useEventName } from "../util/db";
import { Tab, Tabs } from "react-bootstrap";
import EventManagePlayers from "../components/EventManagePlayers";
import EventManageComplete from "../components/EventManageComplete";
// import EventManageCards from '../components/EventManageCards';
import EventManageCardsFull from "../components/EventManageCardsFull";
import Meta from "../components/Meta";
import EventManageChat from "../components/EventManageChat";
// import EventManagePlayersViewExport from '../components/EventManagePlayersViewExport';
import { usePlayersByEvent } from "../util/db";
import EventManageSettings from "../components/EventManageSettings";
import SectionHeader from "../components/SectionHeader";

function EventManagePage() {
  const router = useRouter();
  const eventId = router.query.event;
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : "";
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tdId, setTdId] = useState("");
  const [isEventComplete, setIsEventComplete] = useState(false);
  const [eventName, setEventName] = useState("");
  const [scoringOrderGolf, setScoringOrderGolf] = useState(null);
  const [throwScoringModeId, setThrowScoringModeId] = useState();

  const {
    data: itemsTd,
    status: itemsStatusTd,
    // error: itemsErrorTd,
  } = useEventTdId(eventId);

  useEffect(() => {
    itemsStatusTd !== "loading" &&
      itemsTd &&
      itemsTd.length > 0 &&
      setTdId(itemsTd[0].td_id);
  }, [itemsTd, itemsStatusTd]);

  useEffect(() => {
    if (uid !== "") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [uid]);

  useEffect(() => {
    if (auth.user && auth.user.user_access_level_id === 3) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [auth.user]);

  const {
    data: items,
    status: itemsStatus,
    // error: itemsError,
  } = useEventName(eventId);

  const {
    data: playersItems,
    // status: playersStatus,
    // error: playersError,
  } = usePlayersByEvent(eventId);

  useEffect(() => {
    itemsStatus !== "loading" &&
      items &&
      items.length > 0 &&
      setEventName(items[0].name);
  }, [items, itemsStatus]);
  const title = "Manage " + eventName;

  useEffect(() => {
    itemsStatus !== "loading" &&
      items &&
      items.length > 0 &&
      setScoringOrderGolf(items[0].scoring_order_golf);
  }, [items, itemsStatus]);

  useEffect(() => {
    itemsStatus !== "loading" &&
      items &&
      items.length > 0 &&
      setThrowScoringModeId(items[0].throw_scoring_mode_id);
  }, [items, itemsStatus]);

  useEffect(() => {
    itemsStatus !== "loading" &&
      items &&
      items.length > 0 &&
      setIsEventComplete(items[0].is_event_complete);
  }, [items, itemsStatus]);

  return (
    <>
      <Meta title={title} />
      <Container>
        <div className="text-right">
          <Link to={`/event?event=${eventId}`}>Back to view event</Link>
        </div>
      </Container>
      <span className="mt-4" />
      {!isLoggedIn && (
        <Container>
          Please log in to manage event.
          <br />
          <Link to={`/auth/signin`}>Sign in</Link>
        </Container>
      )}
      {isAdmin || tdId === uid ? (
        <>
          <SectionHeader
            title={"Manage " + eventName}
            size={1}
            className="text-center mb-0"
          />
          <Container>
            <Tabs
              defaultActiveKey="cards"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
              fill
              variant="pills"
              style={{ fontWeight: "bold" }}
            >
              {/* <Tab eventKey="cardsOld" title="Cards (old)">
                  <EventManageCards uid={uid} eventId={eventId}/>
                </Tab> */}
              <Tab eventKey="cards" title="Cards">
                <EventManageCardsFull
                  uid={uid}
                  eventId={eventId}
                  throwScoringModeId={throwScoringModeId}
                />
              </Tab>
              <Tab eventKey="players" title="Edit Players">
                <EventManagePlayers
                  items={playersItems}
                  eventId={eventId}
                  scoringOrderGolf={scoringOrderGolf}
                  throwScoringModeId={throwScoringModeId}
                />
              </Tab>
              <Tab eventKey="chat" title="Chat">
                <EventManageChat eventId={eventId} uid={uid} />
              </Tab>
              <Tab eventKey="settings" title="Settings">
                <EventManageSettings eventId={eventId} uid={uid} />
              </Tab>
              <Tab eventKey="complete" title="Complete">
                <EventManageComplete
                  eventId={eventId}
                  isEventComplete={isEventComplete}
                />
              </Tab>
            </Tabs>
          </Container>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default EventManagePage;
