import React from "react";
import { Alert } from "react-bootstrap";
import { useState } from "react";
import ScorecardSummaryFooterNavigation from "./ScorecardSummaryFooterNavigation";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import ScorecardSummaryTabs from "./ScorecardSummaryTabs";

function ScorecardSummary({
  players,
  maxHole,
  onSubmitScorecard,
  isComplete,
  onJumpToHole,
  currentHole,
  eventId,
  throwScoringModeId,
}) {
  const [emptyHoles] = useState(checkForEmptyHoles());

  //array to store integers
  let holes = [];
  for (let i = 0; i < maxHole; i++) {
    holes.push(i + 1);
  }

  //function to check whether there are any empty holes_scores.score in players array
  function checkForEmptyHoles() {
    let emptyHoles = false;

    //loop through players on this card
    players.forEach((player, index) => {
      //if this player is not dnf, loop through hole scores to check for nulls
      player.event_entries.is_dnf === false &&
        player.event_entries.hole_scores.forEach((score, index) => {
          if (score.score === null) {
            emptyHoles = true;
          }
        });
    });
    return emptyHoles;
  }

  return (
    <Container className="mt-3">
      <SectionHeader
        title={"Scorecard Summary"}
        // subtitle={eventDate}
        size={1}
        //   spaced={true}
        className="text-center"
      />
      <ScorecardSummaryTabs
        holes={holes}
        players={players}
        onJumpToHole={onJumpToHole}
        throwScoringModeId={throwScoringModeId}
      />
      {/* <ScorecardSummaryTable holes={holes} players={players} onJumpToHole={onJumpToHole}/> */}
      <br />

      <ScorecardSummaryFooterNavigation
        eventId={eventId}
        currentHole={currentHole}
        onJumpToHole={onJumpToHole}
        isComplete={isComplete}
        emptyHoles={emptyHoles}
        onSubmitScorecard={onSubmitScorecard}
      />

      <div className="text-center mt-3">
        {isComplete === false && (
          <>
            {emptyHoles && (
              <Container>
                <Alert
                  style={{ background: "#BFBFBF", color: "#2D083F" }}
                  className="mt-5"
                >
                  Please enter a score for all throws. If a player DNF (did not
                  finish), please DNF them from the Scorecard Settings page.
                </Alert>
              </Container>
            )}
          </>
        )}
      </div>
    </Container>
  );
}
export default ScorecardSummary;
