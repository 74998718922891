import React from "react";
import Card from "react-bootstrap/Card";
import ScorecardBeginCardInProgress from "./ScorecardBeginCardInProgress";
import ScorecardBeginCardIsComplete from "./ScorecardBeginCardIsComplete";

function ScorecardShowScorecardCard({
  item,
  index,
  gameId,
  throwScoringModeId,
}) {
  return (
    <Card
      key={index}
      className="mb-2"
      border="primary"
      style={{ border: "none" }}
    >
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{ background: "#2D083F", text: "white" }}
      >
        <Card.Title style={{ color: "#ffffff" }}>Scorecard</Card.Title>

        <div
          style={{
            background: "#ffffff",
            height: "30px",
            borderRadius: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {item.group_scorecard_players[0].group_scorecard.is_complete ===
          false ? (
            <span className="mx-2" style={{ color: "#2D083F" }}>
              🥏 Active
            </span>
          ) : (
            <span className="mx-2" style={{ color: "#2D083F" }}>
              ✔️ Verified
            </span>
          )}
        </div>
      </Card.Header>

      <Card.Body
        className="justify-content-center align-items-center"
        style={{ background: "#FFFFFF" }}
      >
        {item.group_scorecard_players[0].group_scorecard.is_complete ===
        false ? (
          //card is in progress
          <ScorecardBeginCardInProgress
            item={item}
            gameId={gameId}
            throwScoringModeId={throwScoringModeId}
          />
        ) : (
          //card is complete
          <ScorecardBeginCardIsComplete
            item={item}
            gameId={gameId}
            throwScoringModeId={throwScoringModeId}
          />
        )}
      </Card.Body>
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{ background: "#2D083F", text: "white" }}
      />
    </Card>
  );
}

export default ScorecardShowScorecardCard;
