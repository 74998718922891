import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import { useEventEntriesByUid } from "./../util/db";
import { Link } from "react-router-dom";
import ScorecardSummaryTable5x4 from "./ScorecardSummaryTable5x4";

function DashboardScorecards({ uid }) {
  function formatDateMMDDYYYY(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }

  const {
    data: eventEntries,
    status: eventEntriesStatus,
    error: eventEntriesError,
  } = useEventEntriesByUid(uid);

  return (
    <>
      {eventEntriesError && (
        <div className="mb-3">
          <FormAlert type="error" message={eventEntriesError.message} />
        </div>
      )}
      {eventEntriesStatus === "loading" && (
        <div className="py-5 px-3 align-self-center text-center">
          <Spinner animation="border" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {/* if no events */}
      {eventEntriesStatus !== "loading" &&
        eventEntries &&
        eventEntries.length === 0 && (
          <div className="py-5 px-3 align-self-center text-center">
            No scorecards yet
          </div>
        )}

      {console.log(eventEntries)}

      {eventEntriesStatus !== "loading" &&
        eventEntries &&
        eventEntries.length > 0 &&
        eventEntries.map((entry, index) => (
          <Card key={index} className="mb-3">
            <Card.Header>
              <Link
                to={`/event?event=${entry.events.id}`}
                style={{ color: "#2D083F" }}
              >
                <h5 className="m-0" style={{ fontWeight: "bold" }}>
                  {entry.events.name} ({formatDateMMDDYYYY(entry.events.date)})
                </h5>
              </Link>
            </Card.Header>

            <Card.Body>
              <ScorecardSummaryTable5x4
                key={index}
                eventEntryId={entry.id}
                throwScoringModeId={entry.events.throw_scoring_mode_id}
              />
            </Card.Body>
          </Card>
        ))}
    </>
  );
}

export default DashboardScorecards;
