function ViewScorecardHeaderRow({ numHoles, throwScoringModeId }) {
  const holes = Array.from({ length: numHoles }, (_, i) => i + 1);

  return (
    <tr>
      <th
        className="text-center align-middle sticky-col"
        style={{
          color: "#FFFFFF",
          width: "30px",
          tableLayout: "auto",
          border: "2px solid #2D083F",
          position: "sticky",
          left: 0,
          background: "#2D083F",
        }}
      ></th>
      {/* <th onClick={()=>onJumpToHole(index+1)} key={index} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white"}}> */}
      {holes.map((hole, index) => (
        <th
          // onClick={()=>onJumpToHole(index+1)}
          key={index}
          className="text-center align-middle"
          style={{
            width: "30px",
            tableLayout: "auto",
            border: "2px solid #2D083F",
            cursor: "pointer",
            color:
              (index + 1) % 4 === 1
                ? "#FFFFFF"
                : (index + 1) % 4 === 2
                ? "#2D083F"
                : (index + 1) % 4 === 3
                ? "#FFFFFF"
                : "#2D083F",
            background:
              (index + 1) % 4 === 1
                ? "#FF0000"
                : (index + 1) % 4 === 2
                ? "#FFFFFF"
                : (index + 1) % 4 === 3
                ? "#0000FF"
                : "#FFFF00",
          }}
        >
          {hole}
        </th>
      ))}
      <th
        className="text-center align-middle"
        style={{
          width: "30px",
          tableLayout: "auto",
          border: "2px solid #2D083F",
          color: "white",
        }}
      >
        Total
      </th>
      {throwScoringModeId > 1 && (
        <th
          className="text-center align-middle"
          style={{
            width: "30px",
            tableLayout: "auto",
            border: "2px solid #2D083F",
            color: "white",
          }}
        >
          Score+
        </th>
      )}

      {/* <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white" }}>&#9734;</th> */}
    </tr>
  );
}

export default ViewScorecardHeaderRow;
