import React from "react";
import { usePlayersNotPlayingYet } from "../util/db";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ListGroupItem } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

function ScorecardShowCheckedInPlayers({ eventId }) {
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = usePlayersNotPlayingYet(eventId);

  //   display items
  return (
    <>
      {itemsStatus === "error" && console.log(itemsError, "error")}

      <Container className="mt-3">
        <Row>
          <Col>
            {itemsStatus === "success" && items && items.length > 0 && (
              <h6 className="justify-content-center text-center">
                Checked In Players
              </h6>
            )}
            <div>
              {itemsStatus === "loading" && (
                <div className="py-5 px-3 align-self-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              {itemsStatus === "success" && items && items.length > 0 && (
                <>
                  <ListGroup as="ul">
                    {items.map((player) => (
                      <ListGroupItem key={player.id} value={player.id}>
                        <div className="d-flex justify-content-between align-items-center">
                          {player.users_public.name}
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </>
              )}

              {itemsError && console.log(itemsError.message)}
            </div>
          </Col>
        </Row>
        <h6 className="justify-content-center text-center mt-2">
          You are not checked in. Please check in above.
        </h6>{" "}
      </Container>
    </>
  );
}

export default ScorecardShowCheckedInPlayers;
