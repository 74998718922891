import { loadStripe } from "@stripe/stripe-js";
import { apiRequest } from "./util";

let stripe;
// Load the Stripe script
loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  // Pin to specific version of the Stripe API
  apiVersion: "2020-08-27",
}).then((stripeInstance) => {
  // Set stripe so all functions below have it in scope
  stripe = stripeInstance;
});

export async function redirectToCheckout(
  totalCharge,
  entryDescription,
  signupObject
) {
  //create string of ids for side games
  // const sideGameIds = signupObject.side_games.toString()

  //if there are side games, create a string of ids. If not, set to empty string
  let sideGameIds = "";
  if (signupObject.side_games.length > 0) {
    sideGameIds = signupObject.side_games.toString();
  }

  // Create a checkout session
  const session = await apiRequest("stripe-create-checkout-session", "POST", {
    signupObject: signupObject,
    totalCharge: totalCharge,
    entryDescription: entryDescription,
    // successUrl: `${window.location.origin}/profile?paid=true`,
    successUrl: `${window.location.origin}/event?event=${signupObject.events_id}`,
    cancelUrl: `${window.location.origin}/`,
    metadata: {
      event_id: signupObject.events_id,
      player_id: signupObject.user_id,
      event_divisions_id: signupObject.event_divisions_id,
      side_games: sideGameIds,
    },
  });

  console.log("session", session);

  // Ensure if user clicks browser back button from checkout they go to /pricing
  // instead of this page or they'll redirect right back to checkout.
  // window.history.replaceState({}, "", "/pricing");

  // Redirect to checkout
  return stripe.redirectToCheckout({
    sessionId: session.id,
  });
}

export async function redirectToBilling() {
  console.log("billing");
  // Create a billing session
  const session = await apiRequest("stripe-create-billing-session", "POST", {
    returnUrl: `${window.location.origin}/settings/general`,
  });

  // Ensure if user clicks browser back button from billing they go to /settings/general
  // instead of this page or they'll redirect right back to billing.
  window.history.replaceState({}, "", "/settings/general");

  // Redirect to billing session url
  window.location.href = session.url;
}
