import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { usePlayerNamePublic } from "./../util/db";
import FormAlert from "./FormAlert";

function DashboardAccountInfo({ auth }) {
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
    // } = usePlayerName(auth.user.id);

    //cast as big int
  } = usePlayerNamePublic(auth.user.id);

  const date = new Date(auth.user.created_at); // Replace with your date variable

  // Extract month, day, and year
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Format the date as MM-DD-YYYY
  const formattedDate = `${month}-${day}-${year}`;

  return (
    <Card>
      <Card.Body>
        <h5 className="mb-3">Account Info</h5>
        <div className="mt-4">
          {itemsError && (
            <div className="mb-3">
              <FormAlert type="error" message={itemsError.message} />
            </div>
          )}

          {itemsStatus === "success" && items && items.length > 0 && (
            <div>Welcome, {items[0].name}!</div>
          )}

          {itemsStatus === "loading" && (
            <div>
              <div>Welcome</div>
            </div>
          )}

          {itemsStatus !== "loading" && (!items || items.length === 0) && (
            <div>Welcome, {auth.user.name}!</div>
          )}

          <div>
            You are signed in as <strong>{auth.user.email}</strong>.
          </div>
          <div>Member Since: {formattedDate}</div>
          <div className="mt-3">
            You can change your account info{` `}
            in{` `}
            <Link to="/settings/general">
              <strong>settings</strong>
            </Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default DashboardAccountInfo;
