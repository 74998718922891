import React, { useEffect } from "react";
import { useRouter } from "../util/router";
import { useState } from "react";
import ScorecardThrowsTable from "../components/ScorecardThrowsTable";
import { useGroupScorecardPlayers } from "../util/db";
import { Alert, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScorecardSummary from "../components/ScorecardSummary";
import { updateScore } from "../util/db";
import { updateScorecardIsSubmitted } from "../util/db";
import { Link } from "../util/router";
import ScorecardSettings from "../components/ScorecardSettings";
import { useAuth } from "../util/auth";
import Meta from "../components/Meta";
import HrCustom from "../components/HrCustom";
import ScorecardFooterNav from "../components/ScorecardFooterNav";
import ScorecardRetryingMessage from "../components/ScorecardRetryingMessage";

function ThrowsScorecardPage() {
  const router = useRouter();
  const scorecardId = router.query.scorecard;
  const auth = useAuth();

  const [currentHole, setCurrentHole] = useState(1);
  const [maxHole, setMaxHole] = useState();
  const [players, setPlayers] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showTotals, setShowTotals] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [eventId, setEventId] = useState();
  const [throwScoringModeId, setThrowScoringModeId] = useState();
  const { data, status } = useGroupScorecardPlayers(scorecardId);

  const [isRetryingHole, setIsRetryingHole] = useState(false);
  const [isRetryingThrowOne, setIsRetryingThrowOne] = useState(false);
  const [isRetryingThrowTwo, setIsRetryingThrowTwo] = useState(false);

  let alertMessage =
    "Please enter a score for all players before moving to the next hole. If a player gets a 0, please enter 0.";

  // Function to check if a player has a null score for a specific hole
  function hasEmptyScoreForHole(player, hole) {
    // Sort this player's hole scores by hole order
    let thisPlayersHoleScores = player.event_entries.hole_scores.sort(
      (a, b) => a.layout_holes.hole_order - b.layout_holes.hole_order
    );

    // Check if this player's hole scores have a null score for this hole
    return thisPlayersHoleScores[hole - 1].score === null;
  }

  // Function which checks players and verifies that all players have a score for all holes up to the current hole
  function checkForEmptyHole() {
    let emptyHole = false;

    // Loop through each hole from 1 to currentHole
    for (let hole = 1; hole <= currentHole; hole++) {
      // Loop through players and check if any of them have a null score for this hole
      for (let player of players) {
        if (hasEmptyScoreForHole(player, hole)) {
          emptyHole = true;
          break;
        }
      }

      // If an empty hole is found, break out of the loop
      if (emptyHole) {
        break;
      }
    }

    if (emptyHole) {
      setIsAlert(true);
    } else {
      setIsAlert(false);
    }

    return emptyHole;
  }

  //function to increase current hole
  function onRightNav() {
    checkForEmptyHole(players, currentHole);

    let thisMaxHole = 20;
    if (maxHole !== null) {
      thisMaxHole = maxHole;
    }
    if (currentHole < thisMaxHole) {
      setCurrentHole(currentHole + 1);
    } else {
      setShowSummary(true);
    }
  }

  //function to decrease current hole
  function onLeftNav() {
    if (showSummary) {
      setShowSummary(false);
    } else {
      if (currentHole > 1) {
        setCurrentHole(currentHole - 1);
      } else {
        // console.log('Min hole reached')
      }
    }
  }

  //function to jump to hole
  function onJumpToHole(hole) {
    setCurrentHole(hole);
    setShowSummary(false);
  }

  //function to toggle summary
  function toggleSummary() {
    setShowSummary(!showSummary);
  }

  //function to submit scorecard
  function onSubmitScorecard() {
    let eventId = players[0].event_entries.event_id;

    // loop through players and calculate total score
    players.forEach((player, index) => {
      let total = 0;
      let totalPlus = 0;
      let isDnf = player.event_entries.is_dnf;

      player.event_entries.hole_scores.forEach((score, index) => {
        //if player does not have a hole score, set this pplayers total score to null
        if (score.score === null) {
          isDnf = true;
        } else {
          total += score.score;
          totalPlus += score.hole_score_adv;
        }
      });
      player.total_score = total;
      if (isDnf) {
        player.total_score = null;

        // local object for score_adv not getting set for some reason.  Need to investigate
        // perhaps player.total_score = total is not necessary at all and neither is player.total_score_adv
        // player.total_score_adv = null;

        total = null;
        totalPlus = null;
      }

      if (!isDnf) {
        updateScore(
          player.event_entries_id,
          { score: total, score_adv: totalPlus },
          eventId
        );
      }
      // branch for isDnf true?

      //set group_scorecard.is_complete to true
      updateScorecardIsSubmitted(scorecardId, eventId, auth.user.uid).then(
        () => {
          router.push("/event?event=" + eventId);
        }
      );
    });
  }

  //on page load, check local storage for current hole. If it exists, set current hole to that value. If not, set current hole to 1
  useEffect(() => {
    const currentHoleObject = JSON.parse(localStorage.getItem("currentHole"));
    if (currentHoleObject) {
      //if currenthole.scorecardid matches the current scorecard, set current hole to the stored value
      if (currentHoleObject.scorecardId === scorecardId) {
        setCurrentHole(currentHoleObject.currentHole);
      }
    }
    // eslint-disable-next-line
  }, []);

  //on page load, set players, maxhole, iscomplete, showtotals, and eventid
  useEffect(
    () => {
      if (status === "success" && data && data.length > 0) {
        // Sort each throw_scores array by throw_number in ascending order
        const sortedData = data.map((player) => {
          player.event_entries.hole_scores.forEach((hole_score) => {
            hole_score.throw_scores.sort(
              (a, b) => a.throw_number - b.throw_number
            );
          });

          // Sort hole_scores by layout_holes.hole_order
          player.event_entries.hole_scores.sort(
            (a, b) => a.layout_holes.hole_order - b.layout_holes.hole_order
          );

          return player;
        });

        setPlayers(sortedData);
        setThrowScoringModeId(
          data[0].event_entries.events.throw_scoring_mode_id
        );

        setMaxHole(20); //set to 20 to fix warning
        // setMaxHole(calcMaxHole(data))
        setIsComplete(data[0].group_scorecard.is_complete);
        setEventId(data[0].event_entries.event_id);
        setShowTotals(data[0].group_scorecard.is_show_totals);
      }
    },
    // eslint-disable-next-line
    [status, data]
  );

  //set current hole to localstorage to persist on browser refresh
  useEffect(() => {
    const currentHoleObject = {
      currentHole: currentHole,
      scorecardId: scorecardId,
    };
    localStorage.setItem("currentHole", JSON.stringify(currentHoleObject));
    // eslint-disable-next-line
  }, [currentHole]);

  // function to open settings component
  function onSettingsClick() {
    if (showSettings) {
      setShowSettings(false);
    } else {
      setShowSettings(true);
    }
  }

  return (
    <>
      <Meta title={"Scorecard"} />
      {auth.user ? (
        <>
          {isAlert && (
            <Container className="mt-2">
              <Alert variant="danger">{alertMessage}</Alert>
            </Container>
          )}

          {isRetryingHole && <ScorecardRetryingMessage holeorthrow={"Hole"} />}
          {isRetryingThrowOne && (
            <ScorecardRetryingMessage holeorthrow={"Throw 1"} />
          )}
          {isRetryingThrowTwo && (
            <ScorecardRetryingMessage holeorthrow={"Throw 2"} />
          )}

          <Row>
            <Col md="12" className="justify-">
              {showSummary ? (
                <ScorecardSummary
                  eventId={eventId}
                  currentHole={currentHole}
                  onJumpToHole={onJumpToHole}
                  isComplete={isComplete}
                  maxHole={maxHole}
                  players={players}
                  onSubmitScorecard={onSubmitScorecard}
                  throwScoringModeId={throwScoringModeId}
                />
              ) : (
                <ScorecardThrowsTable
                  showTotals={showTotals}
                  isComplete={isComplete}
                  players={players}
                  currentHole={currentHole}
                  toggleSummary={toggleSummary}
                  showSummary={showSummary}
                  onLeftNav={onLeftNav}
                  onRightNav={onRightNav}
                  isRetryingHole={isRetryingHole}
                  setIsRetryingHole={setIsRetryingHole}
                  isRetryingThrowOne={isRetryingThrowOne}
                  setIsRetryingThrowOne={setIsRetryingThrowOne}
                  isRetryingThrowTwo={isRetryingThrowTwo}
                  setIsRetryingThrowTwo={setIsRetryingThrowTwo}
                  throwScoringModeId={throwScoringModeId}
                />
              )}
            </Col>
          </Row>
          {!showSummary && (
            <>
              <HrCustom />
              <ScorecardFooterNav
                onSettingsClick={onSettingsClick}
                isComplete={isComplete}
                toggleSummary={toggleSummary}
                eventId={eventId}
              />
            </>
          )}

          {isComplete ? (
            <Container className="mt-3">
              <Alert style={{ background: "#BFBFBF" }}>
                Scorecard has been submitted. Edits are disabled.
              </Alert>
            </Container>
          ) : (
            <div>
              {showSettings && (
                <ScorecardSettings
                  scorecardId={scorecardId}
                  players={players}
                  eventId={eventId}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <Container className="mt-2">
          <p>
            Please
            <Link to={"/auth/signin"}> Login </Link>
            to view scorecard
          </p>
        </Container>
      )}
    </>
  );
}

export default ThrowsScorecardPage;
