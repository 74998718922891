import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEventEntriesScoreboardByDiv } from "../util/db";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import Table from "react-bootstrap/Table";
import { useState } from "react";
import React from "react";
import DisplayScoreboardByDivisionTable from "./DisplayScoreboardByDivisionTable";

function DisplayScoreboardByDivision(props) {
  const eventId = props.eventId;
  const throwScoringModeId = props.throwScoringModeId;

  // get scorecard for this evententryid
  const {
    data: scoreBoard,
    status: scoreBoardStatus,
    error: scoreBoardError,
  } = useEventEntriesScoreboardByDiv(eventId);

  const [numHoles, setNumHoles] = useState(20);

  //get all event_division_ids and names from scoreBoard and add to array. do not allow duplicate event_division_ids
  const divisionArray = [];
  if (scoreBoard) {
    scoreBoard.forEach((division) => {
      if (
        division.event_divisions &&
        division.event_divisions_id &&
        division.event_divisions.divisions.name
      ) {
        //check if division already exists in divisionArray
        let exists = false;
        divisionArray.forEach((item) => {
          if (item[0] === division.event_divisions_id) {
            exists = true;
          }
        });

        if (!exists) {
          const newArray = [
            division.event_divisions_id,
            division.event_divisions.divisions.name,
          ];
          divisionArray.push(newArray);
        }
      }
    });
  }

  return (
    <>
      <Row>
        <Col>
          {scoreBoardStatus === "loading" && (
            <div className="py-5 px-3 align-self-center">
              <Spinner animation="border" variant="primary" />
            </div>
          )}

          {scoreBoardStatus === "error" && (
            <div className="py-5 px-3 align-self-center">
              <FormAlert type="error" message={scoreBoardError.message} />
            </div>
          )}

          {scoreBoardStatus === "success" &&
            scoreBoard &&
            scoreBoard.length === 0 && (
              <div className="py-5 px-3 align-self-center">
                Nobody playing yet. Please check back later.
              </div>
            )}

          {scoreBoardStatus === "success" &&
            scoreBoard &&
            scoreBoard.length > 0 && (
              <>
                {scoreBoard[0].hole_scores.length > numHoles &&
                  setNumHoles(scoreBoard[0].hole_scores.length)}

                {/* //loop through divisionArray and display table for each division */}
                {divisionArray.map((division, index) => (
                  <React.Fragment key={index}>
                    <h1>{division[1]}</h1>
                    <Table
                      responsive
                      style={{
                        tableLayout: "auto",
                        border: "2px solid #2D083F",
                        background: "#FFFFFF",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            className="text-center align-middle"
                            style={{
                              width: "30px",
                              tableLayout: "auto",
                              border: "2px solid #2D083F",
                              position: "sticky",
                              left: "0px",
                              background: "#2D083F",
                            }}
                          ></th>
                          {[...Array(numHoles)].map((x, index) => (
                            <th
                              className="text-center align-middle"
                              key={index}
                              style={{
                                width: "30px",
                                tableLayout: "auto",
                                border: "2px solid #2D083F",
                                color:
                                  (index + 1) % 4 === 1
                                    ? "#FFFFFF"
                                    : (index + 1) % 4 === 2
                                    ? "#000000"
                                    : (index + 1) % 4 === 3
                                    ? "#FFFFFF"
                                    : "#000000",
                                background:
                                  (index + 1) % 4 === 1
                                    ? "#FF0000"
                                    : (index + 1) % 4 === 2
                                    ? "#FFFFFF"
                                    : (index + 1) % 4 === 3
                                    ? "#0000FF"
                                    : "#FFFF00",
                              }}
                            >
                              {index + 1}
                            </th>
                          ))}
                          <th
                            className="text-center align-middle"
                            style={{
                              width: "30px",
                              tableLayout: "auto",
                              background: "#2D083F",
                              border: "2px solid #2D083F",
                              color: "white",
                            }}
                          >
                            Total
                          </th>
                          {throwScoringModeId > 1 && (
                            <th
                              className="text-center align-middle"
                              style={{
                                width: "30px",
                                tableLayout: "auto",
                                background: "#2D083F",
                                border: "2px solid #2D083F",
                                color: "white",
                              }}
                            >
                              Score+
                            </th>
                          )}

                          {/* star icon */}
                          {/* <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", background:"#2D083F", border: "2px solid #2D083F", color:"white" }}>☆</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <DisplayScoreboardByDivisionTable
                          eventId={eventId}
                          scoreBoard={scoreBoard}
                          numHoles={numHoles}
                          divisionId={division[0]}
                          throwScoringModeId={throwScoringModeId}
                        />
                      </tbody>
                    </Table>
                  </React.Fragment>
                ))}
                <br />
              </>
            )}
        </Col>
      </Row>
    </>
  );
}

export default DisplayScoreboardByDivision;
