import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

function EventCreateFormNav({
  step,
  numSteps,
  formName,
  selectedDivisions,
  handleSubmit,
  handleNext,
  handlePrevious,
  isCreatingEvent,
}) {
  return (
    <div className="d-flex justify-content-between">
      {step > 1 && (
        <Button variant="secondary" onClick={handlePrevious}>
          Previous
        </Button>
      )}
      {step < numSteps &&
        (step === 1 && formName === "" ? (
          <Button variant="primary" disabled>
            Next
          </Button>
        ) : step === 2 && selectedDivisions.length === 0 ? (
          <Button variant="primary" disabled>
            Next
          </Button>
        ) : (
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        ))}

      {step === numSteps && (
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={isCreatingEvent}
        >
          {/* Create Event */}
          {isCreatingEvent ? (
            <>
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="align-baseline"
              />
              Creating Event...
            </>
          ) : (
            "Create Event"
          )}
        </Button>
      )}
    </div>
  );
}

export default EventCreateFormNav;
