import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import "./ScorecardThrowsRowScoreBoxCol.css"; // Import the custom CSS

function ScorecardThrowsRowScoreBoxCol({
  colSkelaton,
  throwScore,
  gameId,
  handleThrowScoreChange,
  throwScoresId,
  throwId,
  isRetryingThrowScore,
  isUpdatingThrowScore,
}) {
  //function to handle the click event on the col
  function onClickThrowsScoreCol() {
    // console.log(throwScore, "throwScore");
    // console.log(colSkelaton, "colSkelaton");
    // console.log(parseInt(colSkelaton), "parseInt(colSkelaton)");
    // console.log(
    //   parseInt(colSkelaton) === throwScore,
    //   "parseInt(colSkelaton) === throwScore"
    // );
    // console.log(throwScoresId, "throwScoresId");
    // console.log(throwId, "throwId");
    // console.log("----------------------------------");

    //setting throw score
    const newThrowScore =
      parseInt(colSkelaton) === throwScore ? null : parseInt(colSkelaton);

    handleThrowScoreChange(throwScoresId, newThrowScore, throwId);
  }

  // if colstate = throwscore, set isActive to True. Colkelaton needs to casted as an int
  const [isActive, setIsActive] = useState(
    parseInt(colSkelaton) === throwScore
  );

  useEffect(() => {
    setIsActive(parseInt(colSkelaton) === throwScore);
    // eslint-disable-next-line
  }, [throwScore]);

  const style = {
    backgroundColor: isActive ? "#5B107E" : "white",
    textAlign: "center",
    fontSize: "20px",
    filter: !isActive ? "grayscale(100%)" : "none",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    border: "none",
  };

  const badgeStyle = {
    position: "absolute",
    bottom: "5px", // Move the badge up
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#4D81F4",
    color: "white",
    borderRadius: "50%",
    padding: "2px",
    fontSize: "8px",
  };

  let mainText = "";
  let badgeText = "";

  if (gameId === 2) {
    //if bags
    if (colSkelaton === "0") {
      mainText = "🚫";
      badgeText = "Off";
    } else if (colSkelaton === "1") {
      mainText = "📥";
      badgeText = "On";
    } else if (colSkelaton === "2") {
      mainText = "✔️";
      badgeText = "In";
    } else {
      mainText = "?";
      badgeText = "TBD";
    }
  } else {
    //not bags (disc putting)
    if (colSkelaton === "0") {
      mainText = "🚫";
      badgeText = "Miss";
    } else if (colSkelaton === "1") {
      mainText = "⛓️";
      badgeText = "Tap";
    } else if (colSkelaton === "2") {
      mainText = "✔️";
      badgeText = "Make";
    } else if (colSkelaton === "?") {
      mainText = "?";
      badgeText = "TBD";
    }
  }

  return (
    <Col style={style} onClick={() => onClickThrowsScoreCol(colSkelaton)}>
      <div
        className="no-outline"
        style={{ position: "relative", display: "inline-block" }}
      >
        <Button style={style} className="no-outline">
          {isActive && isUpdatingThrowScore ? (
            <Spinner
              animation="border"
              size="sm"
              variant="light"
              // className="custom-spinner"
            />
          ) : (
            mainText
          )}
        </Button>
        {isActive && badgeText && <Badge style={badgeStyle}>{badgeText}</Badge>}
      </div>
    </Col>
  );
}

export default ScorecardThrowsRowScoreBoxCol;
