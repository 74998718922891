import React, { useEffect } from "react";
import { useState } from "react";
import {
  insertScoreEdit,
  updateHoleScore,
  getLayoutHoleId,
  createHoleScore,
  createThrowScore,
} from "../util/db";
import { useAuth } from "../util/auth.js";
import { useRouter } from "../util/router";
import ScorecardRowPlayerTd from "./ScorecardRowPlayerTd.js";
import ScorecardRowHoleinfoToast from "./ScorecardRowHoleinfoToast.js";
import ScorecardRowLeftScoreTd from "./ScorecardRowLeftScoreTd.js";
import ScorecardRightScoreTd from "./ScorecardRightScoreTd.js";
import ScorecardRowScoreboxTd from "./ScorecardRowScoreboxTd.js";

function ScorecardRow({
  player,
  currentHole,
  onUpdateHoleScore,
  isComplete,
  showTotals,
  isRetrying,
  setIsRetrying,
}) {
  const router = useRouter();
  const auth = useAuth();
  const [showHoleInfo, setShowHoleInfo] = useState(false);
  const [dbResponseScore, setDbResponseScore] = useState(null);
  const [thisScore, setThisScore] = useState();
  const [isUpdatingScore, setIsUpdatingScore] = useState(false);
  const toggleHoleInfo = () => setShowHoleInfo(!showHoleInfo);

  const defaultScore = 3;
  const event_entries_id = player.event_entries_id;
  const hole_scores_id = getHolescoreId();
  const uid = auth.user ? auth.user.uid : undefined;

  //calculate this players total score
  let total = 0;
  player.event_entries.hole_scores.forEach((score, index) => {
    total += score.score;
  });

  function onViewEdits() {
    //redirect to /holescoreedits
    router.push(
      `/holescoreedits?hole=${hole_scores_id}&event=${player.event_entries.event_id}`
    );
  }

  //function to log hole_score_edit into database
  function logHoleScoreEdit(hole_score_id, newScore, uid) {
    insertScoreEdit({
      hole_scores_id: hole_score_id,
      score: newScore,
      editor_id: uid,
    });
  }

  function onClickLockedScore() {
    alert(
      "This scorecard is locked. Please contact the event organizer to make changes."
    );
  }

  function getHolescoreId() {
    let hole_scores_id = null;
    player.event_entries.hole_scores.map((hole_score, index) => {
      if (hole_score.layout_holes.hole_order === currentHole) {
        hole_scores_id = hole_score.hole_scores_id;
      }
      return hole_scores_id;
    });
    return hole_scores_id;
  }

  async function updateHoleScoreWithRetry(
    hole_scores_id,
    event_entries_id,
    score,
    retries = 10
  ) {
    try {
      const res = await updateHoleScore(hole_scores_id, event_entries_id, {
        score,
      });
      setDbResponseScore(res[0].score);
      setIsUpdatingScore(false);

      //why am i not setting isretryingscore=false?
      setIsRetrying(false);
    } catch (error) {
      setIsRetrying(true);
      if (retries > 0) {
        console.warn(
          `Retrying update score for hole_scores_id ${hole_scores_id}. Retries left: ${retries}`
        );
        setTimeout(() => {
          updateHoleScoreWithRetry(
            hole_scores_id,
            event_entries_id,
            score,
            retries - 1
          ).then(() => setIsRetrying(false));
        }, 5000); // Retry after 5 seconds
      } else {
        console.error(
          `Failed to update score for hole_scores_id ${hole_scores_id} after multiple attempts.`
        );
        setIsUpdatingScore(false);
        setIsRetrying(false);
      }
    }
  }

  function onLeftScore() {
    let newScore = null;
    setIsUpdatingScore(true);

    if (thisScore === undefined || thisScore === null) {
      // console.log('score was null. Set to default')
      newScore = defaultScore - 1;
      if (newScore === 4) {
        newScore = 3;
      }
      setThisScore(newScore);
      // updateHoleScore(hole_scores_id, event_entries_id, {
      //   score: newScore,
      // }).then((res) => {
      //   setDbResponseScore(res[0].score);
      //   setIsUpdatingScore(false);
      // });

      //new
      updateHoleScoreWithRetry(hole_scores_id, event_entries_id, newScore);

      onUpdateHoleScore(hole_scores_id, event_entries_id, newScore);
      logHoleScoreEdit(hole_scores_id, newScore, uid);
    } else if (thisScore > 0) {
      // console.log('score was greater than 0. Allow decrement')
      newScore = thisScore - 1;
      if (newScore === 4) {
        newScore = 3;
      }
      setThisScore(newScore);
      // updateHoleScore(hole_scores_id, event_entries_id, {
      //   score: newScore,
      // }).then((res) => {
      //   setDbResponseScore(res[0].score);
      //   setIsUpdatingScore(false);
      // });

      //new
      updateHoleScoreWithRetry(hole_scores_id, event_entries_id, newScore);

      onUpdateHoleScore(hole_scores_id, event_entries_id, newScore);
      logHoleScoreEdit(hole_scores_id, newScore, uid);
    }
  }

  function onRightScore() {
    let newScore = null;
    setIsUpdatingScore(true);

    if (thisScore === undefined || thisScore === null) {
      // console.log('score was null. Set to default')
      newScore = defaultScore + 1;
      if (newScore === 4) {
        newScore = 5;
      }
      setThisScore(newScore);
      // updateHoleScore(hole_scores_id, event_entries_id, {
      //   score: newScore,
      // }).then((res) => {
      //   setDbResponseScore(res[0].score);
      //   setIsUpdatingScore(false);
      // });

      //new
      updateHoleScoreWithRetry(hole_scores_id, event_entries_id, newScore);

      onUpdateHoleScore(hole_scores_id, event_entries_id, newScore);
      logHoleScoreEdit(hole_scores_id, newScore, uid);
    } else if (thisScore < 5) {
      newScore = thisScore + 1;
      if (newScore === 4) {
        newScore = 5;
      }
      setThisScore(newScore);

      // updateHoleScore(hole_scores_id, event_entries_id, {
      //   score: newScore,
      // }).then((res) => {
      //   setDbResponseScore(res[0].score);
      //   setIsUpdatingScore(false);
      // });

      //new
      updateHoleScoreWithRetry(hole_scores_id, event_entries_id, newScore);

      onUpdateHoleScore(hole_scores_id, event_entries_id, newScore);
      logHoleScoreEdit(hole_scores_id, newScore, uid);
    }
  }

  function onClickScore() {
    setIsUpdatingScore(true);
    if (thisScore === undefined || thisScore === null) {
      //if nothing is entered, set newscore to default
      let newScore = defaultScore;
      setThisScore(newScore);
      // updateHoleScore(hole_scores_id, event_entries_id, {
      //   score: newScore,
      // }).then((res) => {
      //   setDbResponseScore(res[0].score);
      //   setIsUpdatingScore(false);
      // });

      //new
      updateHoleScoreWithRetry(hole_scores_id, event_entries_id, newScore);

      onUpdateHoleScore(hole_scores_id, event_entries_id, newScore);
      logHoleScoreEdit(hole_scores_id, newScore, uid);
    } else {
      //there was already something entered, so overwrite it with null
      let newScore = null;
      setThisScore(newScore);
      // updateHoleScore(hole_scores_id, event_entries_id, {
      //   score: newScore,
      // }).then((res) => {
      //   setDbResponseScore(res[0].score);
      //   setIsUpdatingScore(false);
      // });

      //new
      updateHoleScoreWithRetry(hole_scores_id, event_entries_id, newScore);

      onUpdateHoleScore(hole_scores_id, event_entries_id, newScore);
      logHoleScoreEdit(hole_scores_id, newScore, uid);
    }
  }

  useEffect(() => {
    function getHolescore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      );
      //if this_hole_score[0] is not undefined, return it
      if (this_hole_score[0] !== undefined) {
        return this_hole_score[0].score;
      }
    }

    //if player.event_entries.holes_scores is not empty
    if (player.event_entries.hole_scores.length !== 0) {
      setThisScore(getHolescore());
    } else {
      console.log("error, no hole score for ", currentHole);
    }

    //reset dbResponseScore to null
    setDbResponseScore(null);
  }, [currentHole, player.event_entries.hole_scores]);

  const [isCreating, setIsCreating] = useState(false);

  // Create a hole score if it doesn't exist (error state)
  useEffect(() => {
    if (holeScoresIdIsNull() && !isCreating) {
      setIsCreating(true);

      getLayoutHoleId(
        player.event_entries.event_divisions.layout_id,
        currentHole
      )
        .then((res) => {
          const data = {
            event_entries_id: event_entries_id,
            layout_holes_id: res.layout_holes_id,
          };

          createHoleScore(data)
            .then((holeScoreData) => {
              // log the response
              console.log("created hole score", res);

              // Create throw scores for the hole score
              const numThrows = 2; // Number of throws per hole
              const throwScorePromises = [];
              for (
                let throwNumber = 1;
                throwNumber <= numThrows;
                throwNumber++
              ) {
                const dataThrowScore = {
                  hole_scores_id: holeScoreData[0].hole_scores_id,
                  throw_number: throwNumber,
                };
                throwScorePromises.push(createThrowScore(dataThrowScore));
              }

              // Wait for all throw scores to be created
              Promise.all(throwScorePromises)
                .then(() => {
                  console.log(
                    "created throw scores for hole score",
                    holeScoreData
                  );
                  setIsCreating(false); // Reset the flag after creation
                  window.location.reload();
                })
                .catch((err) => {
                  console.error("Error creating throw scores:", err);
                  setIsCreating(false); // Reset the flag in case of error
                });
            })
            .catch(() => {
              // console.error('Error creating hole score:', err);
              setIsCreating(false); // Reset the flag in case of error
            });
        })
        .catch(() => {
          // console.error('Error getting layout hole ID:', err);
          setIsCreating(false); // Reset the flag in case of error
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hole_scores_id, currentHole, event_entries_id]);

  function holeScoresIdIsNull() {
    return hole_scores_id === null;
  }

  return (
    <>
      <tr>
        <ScorecardRowPlayerTd
          playerName={player.event_entries.users_public.name}
          showTotals={showTotals}
          total={total}
          toggleHoleInfo={toggleHoleInfo}
        />
        <ScorecardRowLeftScoreTd
          onClickLockedScore={onClickLockedScore}
          onClickLeftScore={onLeftScore}
          isComplete={isComplete}
          hole_scores_id={hole_scores_id}
        />
        <ScorecardRowScoreboxTd
          isUpdatingScore={isUpdatingScore}
          isComplete={isComplete}
          thisScore={thisScore}
          hole_scores_id={hole_scores_id}
          dbResponseScore={dbResponseScore}
          onClickScore={onClickScore}
          onClickLockedScore={onClickLockedScore}
        />
        <ScorecardRightScoreTd
          isComplete={isComplete}
          hole_scores_id={hole_scores_id}
          onClickLockedScore={onClickLockedScore}
          onRightScore={onRightScore}
        />
      </tr>
      <tr>
        <ScorecardRowHoleinfoToast
          player={player}
          currentHole={currentHole}
          showHoleInfo={showHoleInfo}
          toggleHoleInfo={toggleHoleInfo}
          onViewEdits={onViewEdits}
        />
      </tr>
    </>
  );
}

export default ScorecardRow;
