import { Button } from "react-bootstrap";
// import ScorecardSummaryTablePreview from './ScorecardSummaryTablePreview';
import { useRouter } from "../util/router";
import ScorecardSummaryTable5x4 from "./ScorecardSummaryTable5x4";

function ScorecardBeginCardIsComplete({ item, gameId, throwScoringModeId }) {
  const router = useRouter();

  return (
    <div className="text-center">
      <ScorecardSummaryTable5x4
        scorecard={item.group_scorecard_players[0].group_scorecard_id}
        eventEntryId={item.id}
        throwScoringModeId={throwScoringModeId}
      />
      <Button
        style={{ background: "#4D81F4", fontWeight: "bold", border: "0" }}
        // onClick={() => router.push(`/scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`)}
        // if gameid1 is 2, go to throwscorecard. else go to scorecard
        onClick={
          gameId === 2
            ? () =>
                router.push(
                  `/throws_scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`
                )
            : () =>
                router.push(
                  `/throws_scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`
                )
        }
        className="mt-2"
      >
        View Scorecard
      </Button>
    </div>
  );
}

export default ScorecardBeginCardIsComplete;
