import React from "react";
import { Link } from "../util/router";
import { ListGroup } from "react-bootstrap";

function ScorecardSettings({ eventId, scorecardId }) {
  // const location = useLocation();
  // const isThrowsScorecard = location.pathname === "/throws_scorecard";

  return (
    <ListGroup>
      <ListGroup.Item>
        <Link
          to={`/scorecard/addplayer?event=${eventId}&scorecard=${scorecardId}`}
        >
          Add Player
        </Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link
          to={`/scorecard/removeplayer?event=${eventId}&scorecard=${scorecardId}`}
        >
          Remove Player
        </Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link
          to={`/scorecard/dnfplayer?event=${eventId}&scorecard=${scorecardId}`}
        >
          DNF Player
        </Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link
          to={`/scorecard/showhidetotals?event=${eventId}&scorecard=${scorecardId}`}
        >
          Show/Hide Totals
        </Link>
      </ListGroup.Item>
      {/* <ListGroup.Item>
        <Link
          to={
            isThrowsScorecard
              ? `/scorecard?scorecard=${scorecardId}`
              : `/throws_scorecard?scorecard=${scorecardId}`
          }
        >
          {isThrowsScorecard ? "Point Scorecard" : "Throw Scorecard"}
        </Link>
      </ListGroup.Item> */}
      <ListGroup.Item>
        <Link to={`/scorecard/order?scorecard=${scorecardId}`}>
          Scorecard Order
        </Link>
      </ListGroup.Item>
    </ListGroup>
  );
}

export default ScorecardSettings;
