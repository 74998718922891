import React from "react";
import ScorecardScoreBox from "./ScorecardScoreBox";

function ScorecardRowScoreboxTd({
  isUpdatingScore,
  isComplete,
  thisScore,
  hole_scores_id,
  dbResponseScore,
  onClickScore,
  onClickLockedScore,
}) {
  return isComplete ? (
    <td
      style={{ textAlign: "center", width: "30px" }}
      onClick={() => onClickLockedScore()}
    >
      <ScorecardScoreBox
        backgroundColor={"#ffffff"}
        textColor={"#2D083F"}
        text={thisScore}
      />
    </td>
  ) : thisScore === null || thisScore === undefined ? ( //this means there was no score entered yet
    hole_scores_id !== null ? ( //if hole_scores_id is null, then the hole is not present in the database
      <td style={{ textAlign: "center" }} onClick={() => onClickScore()}>
        <ScorecardScoreBox
          backgroundColor={"#ffffff"}
          textColor={"#BFBFBF"}
          text={"?"}
        />
      </td>
    ) : (
      //hole_scores_id is null. This is an error state
      <td style={{ textAlign: "center" }} onClick={() => onClickScore()}>
        <ScorecardScoreBox
          backgroundColor={"red"}
          textColor={"#d9e3ff"}
          text={""}
        />
      </td>
    )
  ) : (
    //thisScore is not null or undefined. This means that there was already a score entered
    <>
      {dbResponseScore !== null ? ( //if dbResponseScore is not null, then the score was saved to the database
        <td style={{ textAlign: "center" }} onClick={() => onClickScore()}>
          <ScorecardScoreBox
            backgroundColor={"#ffffff"}
            textColor={"#2D083F"}
            text={thisScore}
          />
        </td>
      ) : (
        //the score is not saved to the database yet. or hasnt been tried either. need to have black text if there is already a score in the db. and gray if there is not yet
        <>
          {isUpdatingScore ? (
            <td style={{ textAlign: "center" }} onClick={() => onClickScore()}>
              <ScorecardScoreBox
                backgroundColor={"#ffffff"}
                textColor={"#c0b4c5"}
                text={thisScore}
              />
            </td>
          ) : (
            <td style={{ textAlign: "center" }} onClick={() => onClickScore()}>
              <ScorecardScoreBox
                backgroundColor={"#ffffff"}
                textColor={"#2D083F"}
                text={thisScore}
              />
            </td>
          )}
        </>
      )}
    </>
  );
}

export default ScorecardRowScoreboxTd;
