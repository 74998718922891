import { useRouter } from "../util/router";
import { usePlayersNotPlayingYet } from "../util/db";
import Spinner from "react-bootstrap/Spinner";
import ScorecardAddPlayerSelect from "../components/ScorecardAddPlayerSelect";
import { Button } from "react-bootstrap";

function AddToScorecardPage() {
  const router = useRouter();
  const scorecardId = router.query.scorecard;
  const eventId = router.query.event;

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = usePlayersNotPlayingYet(eventId);

  return (
    <>
      <div className="container">
        <div className="py-2 px-3 align-self-center">
          <h1 className="text-center">Add Player</h1>
        </div>
        <div className="py-2 px-3 align-self-center">
          <div>
            {itemsStatus === "loading" && (
              <div className="py-5 px-3 align-self-center">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {itemsStatus === "success" && items && items.length > 0 && (
              <>
                <h6 className="justify-content-center text-center">
                  Available Players
                </h6>
                <ScorecardAddPlayerSelect
                  items={items}
                  eventId={eventId}
                  scorecardId={scorecardId}
                />
              </>
            )}
            {/* if no players available, display that to user */}
            {itemsStatus === "success" && items && items.length === 0 && (
              <div className="py-5 px-3 align-self-center">
                <h6 className="justify-content-center text-center">
                  No players available
                </h6>
              </div>
            )}
            <hr />
            <div className="text-center">
              Checking in another player?{" "}
              <Button
                variant="link"
                onClick={() => router.push(`/register_guest?id=${eventId}`)}
              >
                Click here
              </Button>
            </div>
            <hr />
            {/* //link back to scorecard */}
            <div className="py-5 px-3 align-self-center">
              <a
                href={`/throws_scorecard?event=${eventId}&scorecard=${scorecardId}`}
              >
                Back to Scorecard
              </a>
            </div>
            {itemsError && console.log(itemsError.message)}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddToScorecardPage;
