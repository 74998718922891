import React from "react";

const ScorecardSummaryRow = ({ player, throwScoringModeId }) => {
  const holes = player.event_entries.hole_scores;
  // sort holes by hole_order
  holes.sort((a, b) =>
    a.layout_holes.hole_order > b.layout_holes.hole_order
      ? 1
      : b.layout_holes.hole_order > a.layout_holes.hole_order
      ? -1
      : 0
  );

  //calculate this players total score
  let total = 0;
  let isEmptyHole = "false";
  let numFives = 0;
  player.event_entries.hole_scores.forEach((score, index) => {
    total += score.score;
    if (score.score === null) {
      isEmptyHole = "true";
    }
    if (score.score === 5) {
      numFives++;
    }
  });

  //calculate this players total+ score
  let totalPlus = 0;
  player.event_entries.hole_scores.forEach((score, index) => {
    totalPlus += score.hole_score_adv;
  });

  return (
    <tr>
      <td
        className="align-middle"
        style={{
          width: "30px",
          tableLayout: "auto",
          border: "2px solid #2D083F",
          color: "#2D083F",
          position: "sticky",
          left: 0,
          background: "#FFFFFF",
          fontWeight: "bold",
          fontStyle: "italic",
        }}
      >
        {player.event_entries.users_public.name}
      </td>
      {/* loop through holes and print td for each */}
      {holes.map((score, index) => (
        <React.Fragment key={index}>
          {score.score == null ? ( //if score null, print "-"
            <td
              key={index}
              className="text-center align-middle"
              style={{
                width: "30px",
                tableLayout: "auto",
                border: "2px solid #2D083F",
                color: "#2D083F",
                background: "#C9CBE7",
                position: "relative",
              }}
            >
              -
            </td>
          ) : (
            //if score not null, print score or hole_score_adv based on throwScoringModeId
            <td
              key={index}
              className="text-center align-middle"
              style={{
                fontWeight: "bold",
                width: "30px",
                tableLayout: "auto",
                border: "2px solid #2D083F",
                color: "#2d083f",
                background: "#FFFFFF",
                position: "relative",
              }}
            >
              <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                {throwScoringModeId > 1 ? score.hole_score_adv : score.score}
              </span>
              {throwScoringModeId > 1 && (
                <span
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "2px",
                    fontSize: "10px",
                    background: "#FFFFFF",
                    border: "1px solid #2D083F",
                    padding: "2px",
                  }}
                >
                  {score.score}
                </span>
              )}
            </td>
          )}
        </React.Fragment>
      ))}

      {/* {score.score === 5 ?
                                    <span 
                                        style={{
                                            // color: 
                                            //     (index+1)%4===1 ? "#FF0000" : //1
                                            //     (index+1)%4===2 ? "#000000" : //2
                                            //     (index+1)%4===3 ? "#0000FF" : //3
                                            //     (index+1)%4===0 ? "000000" : //4
                                            //     "#000000",
                                            // background: 
                                            //     (index+1)%4===1 ? "#FFFFFF" : 
                                            //     (index+1)%4===2 ? "#FFFFFF" : 
                                            //     (index+1)%4===3 ? "#FFFFFF" : 
                                            //     (index+1)%4===0 ? "000000" :
                                            //     "#FFFFFF"
                                            // color: (index+1)%4===1 ? "#FF0000" : (index+1)%4===2 ? "#000000" : (index+1)%4===3 ? "#0000FF" : (index+1)%4===0 ? "#000000" : "#FFFFFF",
                                            // backgroundColor: (index+1)%4===1 ? "#FFFFFF" : (index+1)%4===2 ? "#FFFFFF" : (index+1)%4===3 ? "#FFFFFF" : (index+1)%4===0 ? "#FFFF00" : "#FFFF00",
                                            color: "#2d083f",
                                            backgroundColor: "#ffffff"
                                        }}
                                    >
                                        &#9734;
                                    </span> 
                                :  */}

      {/* total score */}
      {player.event_entries.is_dnf ? (
        isEmptyHole === "true" ? ( //empty holes and player DNF
          <td
            className="align-middle text-center"
            style={{
              width: "30px",
              tableLayout: "auto",
              border: "2px solid #2D083F",
              color: "black",
              background: "#C9CBE7",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            DNF
          </td>
        ) : (
          //no empty holes and player DNF
          <td
            className="align-middle text-center"
            style={{
              width: "30px",
              tableLayout: "auto",
              border: "2px solid #2D083F",
              color: "black",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            DNF
          </td>
        )
      ) : isEmptyHole === "true" ? ( //empty holes and not DNF
        <td
          className="align-middle text-center"
          style={{
            width: "30px",
            tableLayout: "auto",
            border: "2px solid #2D083F",
            color: "black",
            background: "#C9CBE7",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          {total}
        </td>
      ) : (
        //no empty holes and not DNF
        <td
          className="align-middle text-center"
          style={{
            width: "30px",
            tableLayout: "auto",
            border: "2px solid #2D083F",
            color: "black",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          {total}
        </td>
      )}

      {throwScoringModeId > 1 &&
        (player.event_entries.is_dnf ? (
          isEmptyHole === "true" ? ( //empty holes and player DNF
            <td
              className="align-middle text-center"
              style={{
                width: "30px",
                tableLayout: "auto",
                border: "2px solid #2D083F",
                color: "black",
                background: "#C9CBE7",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              DNF
            </td>
          ) : (
            //no empty holes and player DNF
            <td
              className="align-middle text-center"
              style={{
                width: "30px",
                tableLayout: "auto",
                border: "2px solid #2D083F",
                color: "black",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              DNF
            </td>
          )
        ) : isEmptyHole === "true" ? ( //empty holes and not DNF
          <td
            className="align-middle text-center"
            style={{
              width: "30px",
              tableLayout: "auto",
              border: "2px solid #2D083F",
              color: "black",
              background: "#C9CBE7",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            {totalPlus}
          </td>
        ) : (
          //no empty holes and not DNF
          <td
            className="align-middle text-center"
            style={{
              width: "30px",
              tableLayout: "auto",
              border: "2px solid #2D083F",
              color: "black",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            {totalPlus}
          </td>
        ))}

      {/* star counter */}
      {isEmptyHole === "true" ? (
        <td
          className="align-middle text-center"
          style={{
            width: "30px",
            tableLayout: "auto",
            border: "2px solid #2D083F",
            color: "black",
            background: "#C9CBE7",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          {numFives}
        </td>
      ) : (
        <td
          className="align-middle text-center"
          style={{
            width: "30px",
            tableLayout: "auto",
            border: "2px solid #2D083F",
            color: "black",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          {numFives}
        </td>
      )}
    </tr>
  );
};

export default ScorecardSummaryRow;
