import React from "react";
import ScorecardThrowsRow from "./ScorecardThrowsRow";
import Table from "react-bootstrap/Table";
import ScorecardNavbarTop from "./ScorecardNavbarTop";

const ScorecardThrowsTable = ({
  showTotals,
  players,
  currentHole,
  isComplete,
  onLeftNav,
  onRightNav,
  showSummary,
  toggleSummary,
  isRetryingHole,
  setIsRetryingHole,
  isRetryingThrowOne,
  setIsRetryingThrowOne,
  isRetryingThrowTwo,
  setIsRetryingThrowTwo,
  throwScoringModeId,
}) => {
  return (
    <>
      <ScorecardNavbarTop
        currentHole={currentHole}
        onLeftNav={onLeftNav}
        onRightNav={onRightNav}
        showSummary={showSummary}
        toggleSummary={toggleSummary}
      />

      <Table
        size="sm"
        borderless
        style={{ tableLayout: "fixed", wordWrap: "break-word" }}
      >
        <tbody>
          <tr
            style={{
              backgroundColor: "#2D083F",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            <th style={{ textAlign: "left" }}>Player</th>
            <th>Throw 1</th>
            <th>Throw 2</th>
            <th style={{ width: "80px" }}>Score</th>
            {throwScoringModeId > 1 && (
              <th style={{ width: "80px" }}>Score+</th>
            )}
          </tr>
          {players.map((player, index) => (
            <ScorecardThrowsRow
              showTotals={showTotals}
              isComplete={isComplete}
              key={index}
              player={player}
              currentHole={currentHole}
              isRetryingHole={isRetryingHole}
              setIsRetryingHole={setIsRetryingHole}
              isRetryingThrowOne={isRetryingThrowOne}
              setIsRetryingThrowOne={setIsRetryingThrowOne}
              isRetryingThrowTwo={isRetryingThrowTwo}
              setIsRetryingThrowTwo={setIsRetryingThrowTwo}
              throwScoringModeId={throwScoringModeId}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ScorecardThrowsTable;
