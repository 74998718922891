import React from "react";
import Table from "react-bootstrap/Table";
import EventsTableRow from "./EventsTableRow";

function EventsTable({ events }) {
  return (
    <Table responsive className="table table-striped">
      <thead style={{ background: "#2D083F" }}>
        <tr>
          <th
            className="text-center"
            style={{ color: "#ffffff", width: "10%" }}
          >
            Date
          </th>
          <th className="text-start" style={{ color: "#ffffff" }}>
            Name
          </th>
          {/* <th
            className="text-center"
            style={{ color: "#ffffff", width: "10%" }}
          >
            Game
          </th> */}
          <th
            className="text-center"
            style={{ color: "#ffffff", width: "10%" }}
          >
            Course
          </th>
        </tr>
      </thead>
      <tbody>
        {events.map((event, index) => (
          <EventsTableRow key={index} event={event} />
        ))}
      </tbody>
    </Table>
  );
}

export default EventsTable;
