import React from "react";
import { Container } from "react-bootstrap";
import { useRouter } from "../util/router";
import { useGroupScorecardPlayers } from "../util/db";
import { useState } from "react";
import ScorecardPlayerDnfRow from "../components/ScorecardPlayerDnfRow";
import { ListGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { updateDnf } from "../util/db";
import { removeGroupScorecardPlayer } from "../util/db";
// import { useAuth } from "../util/auth";


function DnfScorecardPage() {
  // const auth = useAuth();

  async function handleDnfPlayer() {
    try {
      await updateDnf(selectedPlayer, { is_dnf: true }, eventId);
    } catch (error) {
      console.error("Error updating dnf: ", error);
    }

    try {
      await removeGroupScorecardPlayer(selectedPlayerGspId);

      if (items.length === 1) {
        router.push(`/event?event=${eventId}`);
      } else {
        //redirect to scorecard
        router.push(
          `/throws_scorecard?event=${eventId}&scorecard=${scorecardId}`
        );
      }

      //redirect to scoreacdrd. If there are no players left on a scorecard, should redirect to event page
      //   router.push(
      //     `/throws_scorecard?event=${eventId}&scorecard=${scorecardId}`
      //   );
    } catch (error) {
      console.error("Error removing player from scorecard: ", error);
    }
  }

  const router = useRouter();
  const scorecardId = router.query.scorecard;
  const eventId = router.query.event;

  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [selectedPlayerGspId, setSelectedPlayerGspId] = useState();

  //get players from scorecard
  const {
    data: items,
    status: itemsStatus,
    // error: itemsError,
  } = useGroupScorecardPlayers(scorecardId);

  //useeffect to enable confirm button when selectedPlayer is not null
  React.useEffect(() => {
    if (selectedPlayer !== undefined) {
      setConfirmDisabled(false);
    } else {
      setConfirmDisabled(true);
    }
  }, [selectedPlayer]);

  return (
    <Container>
      <h1 className="text-center">DNF Player</h1>
      <p className="text-center">
        Please choose the player you would like to dnf. Please do so if a player
        did not complete the round.
      </p>

      {itemsStatus === "loading" && (
        <div className="py-5 px-3 align-self-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {items && items.length === 0 && (
        <div className="text-center py-5">
          <p className="lead mb-4">No players found.</p>
        </div>
      )}
      {items && items.length > 0 && (
        <>
          <ListGroup as="ul">
            {items.map((item, index) => (
              <ScorecardPlayerDnfRow
                key={index}
                item={item}
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={setSelectedPlayer}
                setSelectedPlayerGspId={setSelectedPlayerGspId}
              />
            ))}
          </ListGroup>
          <br />
          <div className="text-center">
            <Button
              disabled={confirmDisabled}
              className="w-100"
              onClick={handleDnfPlayer}
            >
              DNF Player
            </Button>
          </div>
        </>
      )}

      {/* //link back to scorecard */}
      <div className="py-5 px-3 align-self-center">
        <a href={`/throws_scorecard?event=${eventId}&scorecard=${scorecardId}`}>
          Back to Scorecard
        </a>
      </div>
    </Container>
  );
}

export default DnfScorecardPage;
