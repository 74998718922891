import React, { useState } from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "../components/Section";
import { Link } from "../util/router";
import { requireAuth } from "./../util/auth";
import { ListGroup, FormControl } from "react-bootstrap";
import { usePlayers } from "../util/db";
import {
  createEventEntry,
  getLayoutHoles,
  createHoleScore,
  createThrowScore,
} from "../util/db";
import HrCustom from "../components/HrCustom";
import { useEventDivisions } from "../util/db";
import Spinner from "react-bootstrap/Spinner";

function RegisterGuestPage(props) {
  const numThrows = 2;
  const router = useRouter();
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Add state for search query
  const [isRegistering, setIsRegistering] = useState(false);

  const { data: items, status: itemsStatus } = useEventDivisions(
    router.query.id
  );

  const { data: players, status: playersStatus } = usePlayers();

  const [signupObject, setSignupObject] = useState({
    event_divisions_id: null,
    division_name: "",
    division_cost: 0,
    side_games: [],
    side_games_cost: 0,
    event_id: router.query.id,
    user_id: selectedPlayer,
    layout_id: null,
  });

  const handleSetDivision = (
    event_divisions_id,
    division_cost,
    division_name,
    layout_id
  ) => {
    setSelectedDivision(event_divisions_id);
    const newSignupObject = { ...signupObject };
    newSignupObject.event_divisions_id = event_divisions_id;
    newSignupObject.division_cost = division_cost;
    newSignupObject.division_name = division_name;
    newSignupObject.layout_id = layout_id;
    setSignupObject(newSignupObject);
  };

  const handleSetPlayer = (id, name) => {
    setSelectedPlayer(id);
    const newSignupObject = { ...signupObject };
    newSignupObject.user_id = id;
    setSignupObject(newSignupObject);
  };

  const isSelectedPlayer = (this_player_id) => {
    return selectedPlayer === this_player_id;
  };

  const isSelectedDivision = (this_division_id) => {
    return selectedDivision === this_division_id;
  };

  const handlePlayerConfirmClick = () => {
    if (signupObject.event_divisions_id === null) {
      alert("Please select a division");
    } else if (selectedPlayer === null) {
      alert("Please select a player");
    } else {
      setIsRegistering(true);
      const registerObject = {
        event_id: signupObject.event_id,
        user_id: signupObject.user_id,
        event_divisions_id: signupObject.event_divisions_id,
      };

      createEventEntry(registerObject).then((registerData) => {
        getLayoutHoles(signupObject.layout_id).then((layoutData) => {
          const holeScorePromises = layoutData.map((hole) => {
            const dataHoleScore = {
              layout_holes_id: hole.layout_holes_id,
              event_entries_id: registerData[0].id,
            };

            return createHoleScore(dataHoleScore).then((holeScoreData) => {
              const throwScorePromises = [];
              for (
                let throwNumber = 1;
                throwNumber <= numThrows;
                throwNumber++
              ) {
                const dataThrowScore = {
                  hole_scores_id: holeScoreData[0].hole_scores_id,
                  throw_number: throwNumber,
                };

                throwScorePromises.push(createThrowScore(dataThrowScore));
              }
              return Promise.all(throwScorePromises);
            });
          });

          Promise.all(holeScorePromises)
            .then(() => {
              router.push(`/event?event=${signupObject.event_id}`);
            })
            .catch((err) => {
              console.error("Error creating hole scores or throw scores:", err);
              setIsRegistering(false);
            });
        });
      });
    }
  };

  const filteredPlayers = players
    ?.filter((player) =>
      player.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  return (
    <>
      <Meta title="Signup" />

      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <header className={"SectionHeader text-center"}>
            <h1 className="font-weight-bold">Check in</h1>
            <p className="SectionHeader__subtitle mb-0">
              Player must already have an account. If not, please have them
              <Link to={`auth/signup`}> create one </Link> first.
            </p>
          </header>
        </Container>
      </Section>

      <Container>
        <p className="text-center">Please Choose Player</p>
        <FormControl
          type="text"
          placeholder="Search players"
          className="mb-3"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <ListGroup as="ul">
          {playersStatus !== "loading" &&
            filteredPlayers &&
            filteredPlayers.length > 0 &&
            filteredPlayers.map((item, index) =>
              isSelectedPlayer(item.id) ? (
                <ListGroup.Item
                  as="li"
                  key={index}
                  active
                  style={{ background: "#2D083F" }}
                >
                  <p
                    className="font-weight-bold mb-0"
                    style={{ color: "white" }}
                  >
                    {item.name}
                  </p>
                </ListGroup.Item>
              ) : (
                <ListGroup.Item
                  as="li"
                  key={index}
                  onClick={() => handleSetPlayer(item.id, item.name)}
                >
                  <p className="font-weight-bold mb-0">{item.name}</p>
                </ListGroup.Item>
              )
            )}
        </ListGroup>
      </Container>
      <HrCustom />

      <Container>
        <p className="text-center">Please Choose Division</p>
        <ListGroup as="ul">
          {itemsStatus !== "loading" &&
            items &&
            items.length > 0 &&
            items.map((item, index) =>
              isSelectedDivision(item.event_divisions_id) ? (
                <ListGroup.Item
                  as="li"
                  key={index}
                  active
                  style={{ background: "#2D083F" }}
                >
                  <p
                    className="font-weight-bold mb-0"
                    style={{ color: "white" }}
                  >
                    {item.divisions.name}
                  </p>
                </ListGroup.Item>
              ) : (
                <ListGroup.Item
                  as="li"
                  key={index}
                  onClick={() =>
                    handleSetDivision(
                      item.event_divisions_id,
                      item.division_cost,
                      item.divisions.name,
                      item.layout_id
                    )
                  }
                >
                  <p className="font-weight-bold mb-0">{item.divisions.name}</p>
                </ListGroup.Item>
              )
            )}
        </ListGroup>
      </Container>
      <HrCustom />

      <HrCustom />

      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row>
          <Col lg={6} className="mt-4 mt-lg-0">
            <Button
              variant={props.buttonColor}
              size="lg"
              disabled={isRegistering}
              onClick={() => {
                handlePlayerConfirmClick();
              }}
            >
              {isRegistering ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden={true}
                  className="align-baseline"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Confirm!"
              )}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default requireAuth(RegisterGuestPage);
