import { useState, useEffect } from "react";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import {
  useEventThrowScoringMode,
  useThrowScoringModes,
  updateEventThrowScoringMode,
} from "../util/db";

function EventManageThrowScoringModeChange({ eventId }) {
  const [eventThrowScoringMode, setEventThrowScoringMode] = useState();

  const [throwScoringModes, setThrowScoringModes] = useState([]);
  const [isEditted, setIsEditted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { data: items, status: itemsStatus } =
    useEventThrowScoringMode(eventId);
  const { data: allModes, status: allModesStatus } = useThrowScoringModes();

  useEffect(() => {
    if (itemsStatus !== "loading" && items && items.length > 0) {
      setEventThrowScoringMode(items[0].throw_scoring_mode_id);
    }
  }, [items, itemsStatus]);

  useEffect(() => {
    if (allModesStatus !== "loading" && allModes && allModes.length > 0) {
      setThrowScoringModes(allModes);
    }
  }, [allModes, allModesStatus]);

  const onScoringModeChange = (e) => {
    setEventThrowScoringMode(e.target.value);
    setIsEditted(true);
    setIsSubmitted(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await updateEventThrowScoringMode(eventId, eventThrowScoringMode);
    setIsSubmitted(true);
    setIsSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="name">
        <Row>
          <Col>
            <Form.Label style={{ color: "#2D083F" }}>Scoring Mode</Form.Label>
          </Col>
          <Col>
            <Form.Control
              as="select"
              style={{ color: "#2D083F" }}
              required
              value={eventThrowScoringMode}
              onChange={onScoringModeChange}
            >
              {throwScoringModes.map((mode, index) => (
                <option key={index} value={mode.id}>
                  {mode.name}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Button type="submit" disabled={!isEditted || isSubmitting}>
              {isSubmitted ? (
                "✔"
              ) : isSubmitting ? (
                <Spinner animation="border" />
              ) : (
                "Update"
              )}
            </Button>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
}

export default EventManageThrowScoringModeChange;
