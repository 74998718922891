import React from "react";
import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import {
  updateScore,
  updateDnf,
  updateIsDroppedFalse,
  updatePointsNull,
  updatePlayoffWinnerNull,
  removeGroupScorecardPlayerByEventEntriesId,
} from "./../util/db";
import ConfirmDeletePlayerModal from "./ConfirmDeletePlayerModal";
import { Link } from "react-router-dom";
import EventManagePlayersChangeDivModal from "./EventManagePlayersChangeDivModal";

function EventManagePlayersRow({ player, eventId, throwScoringModeId }) {
  const [score, setScore] = useState(player.score);
  const [scorePending, setScorePending] = useState(false);
  const [dnfPending, setDNFPending] = useState(false);
  const [clearPending, setClearPending] = useState(false);
  const [scorePlus, setScorePlus] = useState(
    player.score_adv ? player.score_adv : null
  );

  function onScore(e) {
    e.preventDefault();
    setScorePending(true);

    //update state
    setScore(score);

    //update dnf in db to false
    updateDnf(player.id, { is_dnf: false }, eventId);

    {
      throwScoringModeId > 1
        ? updateScore(
            player.id,
            { score: score, score_adv: player.score_adv },
            eventId
          )
        : updateScore(player.id, { score: score }, eventId);
    }
    // updateScore(player.id, { score: score }, eventId);

    setScorePending(false);
  }

  function onScorePlus(e) {
    e.preventDefault();
    setScorePending(true);

    //update state
    setScorePlus(scorePlus);

    //update dnf in db
    updateDnf(player.id, { is_dnf: false }, eventId);

    {
      throwScoringModeId > 1
        ? updateScore(
            player.id,
            { score: score, score_adv: scorePlus },
            eventId
          )
        : updateScore(player.id, { score: score }, eventId);
    }

    setScorePending(false);
  }

  function onClear(e) {
    e.preventDefault();
    setClearPending(true);

    //set score to null
    setScore(null);

    {
      throwScoringModeId > 1 && setScorePlus(null);
    }

    // set player as not dnf
    updateDnf(player.id, { is_dnf: false }, eventId);
    updateScore(player.id, { score: null }, eventId);

    {
      throwScoringModeId > 1 &&
        updateScore(player.id, { score_adv: null }, eventId);
    }

    //set dropped to false
    updateIsDroppedFalse(player.id);

    //set points to null
    updatePointsNull(player.id);

    //set playoff winnner to null
    updatePlayoffWinnerNull(player.id);

    //remove hole scores
    // deleteHoleScores(player.id).then(
    //     console.log("scorecard unscored")
    // )

    setClearPending(false);
  }

  async function onDnf(e) {
    e.preventDefault();

    setScore("DNF");
    setScorePlus("DNF");

    // Show pending indicator
    setDNFPending(true);

    updateDnf(player.id, { is_dnf: true }, eventId);
    updateScore(player.id, { score: null }, eventId);

    {
      throwScoringModeId > 1 &&
        updateScore(player.id, { score_adv: null }, eventId);
    }

    console.log(player, "player");

    try {
      await removeGroupScorecardPlayerByEventEntriesId(player.id);
      //update score in db
      setDNFPending(false);
      // force refresh
      window.location.reload();
    } catch (error) {
      console.error("Error removing player from scorecard: ", error);
    }
  }

  return (
    <tr className="text-center" style={{ color: "#2D083F" }}>
      <td style={{ verticalAlign: "middle", position: "sticky", left: "0px" }}>
        <Link to={`/player?id=${player.user_id}`} style={{ color: "#2D083F" }}>
          {player.users_public.name}
        </Link>
      </td>
      <td style={{ verticalAlign: "middle" }}>
        {scorePending ? (
          <Button style={{ backgroundColor: "#512B81" }}>
            <Spinner animation="border" role="status"></Spinner>
          </Button>
        ) : (
          <Form>
            <Form.Group className="mt-3">
              <Form.Control
                style={{ width: "50px" }}
                type="text"
                onChange={(e) => setScore(e.target.value)}
                value={
                  player.is_dnf === true ? "DNF" : score === null ? "" : score
                }
              />
            </Form.Group>
          </Form>
        )}
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <Button style={{ backgroundColor: "#4D81F4" }} onClick={onScore}>
          Score
        </Button>
      </td>
      {throwScoringModeId > 1 && (
        <>
          <td style={{ verticalAlign: "middle" }}>
            {scorePending ? (
              <Button style={{ backgroundColor: "#512B81" }}>
                <Spinner animation="border" role="status"></Spinner>
              </Button>
            ) : (
              <Form>
                <Form.Group className="mt-3">
                  <Form.Control
                    style={{ width: "60px" }}
                    type="text"
                    onChange={(e) => setScorePlus(e.target.value)}
                    value={
                      player.is_dnf === true
                        ? "DNF"
                        : scorePlus === null
                        ? ""
                        : scorePlus
                    }
                  />
                </Form.Group>
              </Form>
            )}
          </td>
          <td style={{ verticalAlign: "middle" }}>
            <Button
              style={{ backgroundColor: "#4D81F4" }}
              onClick={onScorePlus}
            >
              Score+
            </Button>
          </td>
        </>
      )}
      <td style={{ verticalAlign: "middle" }}>
        {clearPending ? (
          <Button variant="outline-secondary">
            <Spinner animation="border" role="status"></Spinner>
          </Button>
        ) : (
          <Button style={{ backgroundColor: "#4D81F4" }} onClick={onClear}>
            Clear
          </Button>
        )}
      </td>

      <td style={{ verticalAlign: "middle" }}>
        {dnfPending ? (
          <Button style={{ backgroundColor: "#4D81F4" }}>
            <Spinner animation="border" role="status"></Spinner>
          </Button>
        ) : (
          <Button style={{ backgroundColor: "#4D81F4" }} onClick={onDnf}>
            DNF
          </Button>
        )}
      </td>
      <td style={{ verticalAlign: "middle" }}>
        {player.event_divisions.divisions.name}
        <EventManagePlayersChangeDivModal
          eventId={eventId}
          eventEntryId={player.id}
          eventDivisionsId={player.event_divisions_id}
          playerName={player.users_public.name}
          divisionName={player.event_divisions.divisions.name}
        />
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <ConfirmDeletePlayerModal
          heading={"Remove Player?"}
          bodyText={
            "Are you sure you want to remove this player from the event?"
          }
          confirmText={"Yes"}
          cancelText={"Cancel"}
          buttonText={"🗑️"}
          eventEntryId={player.id}
          eventId={eventId}
          eventDivisionsId={player.event_divisions_id}
        />
      </td>
    </tr>
  );
}

export default EventManagePlayersRow;
