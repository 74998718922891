import React from "react";
import Table from "react-bootstrap/Table";
import ScorecardSummaryRow from "./ScorecardSummaryRow";
// making this non-change
function ScorecardSummaryTable({
  holes,
  players,
  onJumpToHole,
  throwScoringModeId,
}) {
  return (
    <div>
      <Table
        responsive
        style={{ tableLayout: "auto", border: "2px solid #2D083F" }}
      >
        <thead style={{ background: "#2D083F", text: "white" }}>
          <tr>
            <th
              className="text-center align-middle"
              style={{
                color: "#FFFFFF",
                width: "30px",
                tableLayout: "auto",
                border: "2px solid #2D083F",
                position: "sticky",
                left: 0,
                background: "#2D083F",
              }}
            ></th>
            {/* <th onClick={()=>onJumpToHole(index+1)} key={index} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white"}}> */}
            {holes.map((hole, index) => (
              <th
                onClick={() => onJumpToHole(index + 1)}
                key={index}
                className="text-center align-middle"
                style={{
                  width: "30px",
                  tableLayout: "auto",
                  border: "2px solid #2D083F",
                  cursor: "pointer",
                  color:
                    (index + 1) % 4 === 1
                      ? "#FFFFFF"
                      : (index + 1) % 4 === 2
                      ? "#2D083F"
                      : (index + 1) % 4 === 3
                      ? "#FFFFFF"
                      : "#2D083F",
                  background:
                    (index + 1) % 4 === 1
                      ? "#FF0000"
                      : (index + 1) % 4 === 2
                      ? "#FFFFFF"
                      : (index + 1) % 4 === 3
                      ? "#0000FF"
                      : "#FFFF00",
                }}
              >
                {hole}
              </th>
            ))}
            <th
              className="text-center align-middle"
              style={{
                width: "30px",
                tableLayout: "auto",
                border: "2px solid #2D083F",
                color: "white",
              }}
            >
              Total
            </th>
            {throwScoringModeId > 1 && (
              <th
                className="text-center align-middle"
                style={{
                  width: "30px",
                  tableLayout: "auto",
                  border: "2px solid #2D083F",
                  color: "white",
                }}
              >
                Total+
              </th>
            )}
            <th
              className="text-center align-middle"
              style={{
                width: "30px",
                tableLayout: "auto",
                border: "2px solid #2D083F",
                color: "white",
              }}
            >
              &#9734;
            </th>
          </tr>
        </thead>
        <tbody>
          {players?.map((player, index) => (
            <ScorecardSummaryRow
              player={player}
              key={index}
              throwScoringModeId={throwScoringModeId}
            />
            // <tr key={index}></tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ScorecardSummaryTable;
