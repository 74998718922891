import React from "react";
import ScorecardBeginComponent from "./ScorecardBeginComponent";
import EventScorecardNotLoggedIn from "./EventScorecardNotLoggedIn";
import { useRouter } from "../util/router";

function EventScorecards({
  isLoggedIn,
  uid,
  eventId,
  isEventComplete,
  auth,
  canCheckIn,
  isCheckedInAlready,
  isIncompleteCards,
  gameId,
  throwScoringModeId,
}) {
  const router = useRouter();

  return (
    <>
      {isLoggedIn ? (
        <ScorecardBeginComponent
          isIncompleteCards={isIncompleteCards}
          isCheckedInAlready={isCheckedInAlready}
          canCheckIn={canCheckIn}
          isEventComplete={isEventComplete}
          eventId={eventId}
          isLoggedIn={isLoggedIn}
          userId={uid}
          auth={auth}
          gameId={gameId}
          throwScoringModeId={throwScoringModeId}
        />
      ) : (
        <EventScorecardNotLoggedIn eventId={eventId} router={router} />
      )}
    </>
  );
}

export default EventScorecards;
