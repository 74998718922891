import React, { useState } from "react";
import { useEventHosts, updateEventHost, getUsersNamePublic } from "../util/db";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";

function AdminPageUpdateHost({ eventId }) {
  const {
    data: eventHosts,
    status: eventHostsStatus,
    // error: eventHostsError,
  } = useEventHosts();

  const [hostsWithNames, setHostsWithNames] = useState([]);
  //   hostsWithNames.push({
  //     id: host.id,
  //     name: `${host.first_name} ${host.last_name}`,
  //   });

  //   hostsWithNames.push({
  //     id: host.id,
  //     name: name,
  //     name_: host.name
  //   });

  useEffect(() => {
    if (eventHostsStatus === "success") {
      const fetchHostNames = async () => {
        const hosts = await Promise.all(
          eventHosts.map(async (host) => {
            const name = await getUsersNamePublic(host.id);
            return {
              id: host.id,
              name: name[0].name,
            };
          })
        );
        setHostsWithNames(hosts);
      };

      fetchHostNames();
    }
  }, [eventHostsStatus, eventHosts]);

  const [selectedHost, setSelectedHost] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await updateEventHost(eventId, selectedHost);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Failed to update event host", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  {
    hostsWithNames && hostsWithNames.map((host) => console.log(host));
  }

  console.log(hostsWithNames, "hostsWithNames");

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="eventHostSelect">Select Event Host</label>
          <select
            id="eventHostSelect"
            className="form-control"
            value={selectedHost}
            onChange={(e) => setSelectedHost(e.target.value)}
          >
            <option value="">Select a host</option>
            {hostsWithNames.map((host) => (
              <option key={host.id} value={host.id}>
                {host.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary">
          {isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : isSubmitted ? (
            <span>&#10003;</span>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
}

export default AdminPageUpdateHost;
