import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import FormField from "./FormField";
import { createUserName } from "../util/db";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";

//redisgned to use PromptUsersNameForm

function PromptUsersName(props) {
  const [pending, setPending] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    console.log("saving name", data);
    console.log("pending=", pending);
    // Show pending indicator
    setPending(true);
    console.log("pending=", pending);

    if (pending === true) {
      //add users name to database
      createUserName(data);
      setPending(false);
    } else {
      console.log("pending=", pending);
    }
  };

  return (
    <Container>
      <Card className="mt-5 mb-3">
        <Card.Header
          as="h5"
          className="d-flex justify-content-between align-items-center"
          style={{ background: "#2D083F", text: "white" }}
        >
          <Card.Title style={{ color: "#ffffff" }}>
            Please enter your full name and click save
          </Card.Title>
        </Card.Header>
        <Card.Body
          className="justify-content-between align-items-center"
          style={{ background: "#8CABFF" }}
        >
          {/* <PromptUsersNameForm /> */}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formName">
              <FormField
                name="name"
                type="text"
                label="Full Name"
                placeholder="Full Name"
                size="lg"
                error={errors.name}
                inputRef={register({
                  required: "Please enter your Full Name",
                })}
              />
            </Form.Group>

            <Button type="submit" size="lg" disabled={pending}>
              {!pending ? <span>Save</span> : <span>Saving</span>}

              {pending && (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden={true}
                  className="ml-2 align-baseline"
                >
                  <span className="sr-only">Sending...</span>
                </Spinner>
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default PromptUsersName;
